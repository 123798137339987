<template>
  <div>
    <!-- Overlay to block user interaction -->
    <div v-if="loadingReprocessar" class="overlay"></div>

    <!-- Início send -->
    <v-dialog v-model="modal.sendComplaint" width="500">
      <v-card>
        <v-card-title class="text-h5 red lighten-2" align="center">
          Enviar Reclamação
        </v-card-title>
        <v-card-text>
          <p class="text--primary" align="center">
            Confirmar a Reclamação para
          </p>
          <v-row></v-row>
          <v-row>
            <v-col cols="12" style="padding-bottom: 0px">
              Objeto: {{ objetoAtual }}
            </v-col>
          </v-row>
          <v-row></v-row>
          <v-row>
            <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px">
              Motivo: {{ reclamacao.codigo }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="modal.sendComplaint = false">
            Voltar
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="salvarReclamacao(), (modal.sendComplaint = false)"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- fim send -->

    <!-- Início filtro -->
    <v-dialog
      v-model="modal.filtro"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="#018656" dark>
          <v-icon class="ml-2"> fas fa-boxes </v-icon>
          <v-toolbar-title class="pl-4"> Filtrar objetos </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="modal.filtro = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>
        <v-card-text>
          <v-col class="pt-6">
            <v-form>
              <v-row>
                <v-col cols="12" md="2" class="alinhamento">
                  <v-dialog
                    ref="data_inicio_pesquisa"
                    v-model="modal.data_inicio_pesquisa"
                    :return-value.sync="filtros.data_inicio"
                    persistent
                    width="290px"
                    :retain-focus="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        prepend-icon="fas fa-calendar-alt"
                        label="Data inicial"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :value="formatDateInicio"
                        @click:clear="filtros.data_inicio = null"
                        :disabled="filtros.allDates"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="filtros.data_inicio" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal.data_inicio_pesquisa = false"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.data_inicio_pesquisa.save(filtros.data_inicio)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" md="2" class="alinhamento">
                  <v-dialog
                    ref="data_final_pesquisa"
                    v-model="modal.data_final_pesquisa"
                    :return-value.sync="filtros.data_final"
                    persistent
                    width="290px"
                    :retain-focus="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        prepend-icon="fas fa-calendar-alt"
                        label="Data final"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :value="formatDateFinal"
                        @click:clear="filtros.data_final = null"
                        :disabled="filtros.allDates"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="filtros.data_final" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal.data_final_pesquisa = false"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.data_final_pesquisa.save(filtros.data_final)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" md="2" class="alinhamento">
                  <v-checkbox
                    class="shrink mr-2 mt-1"
                    v-model="filtros.allDates"
                    :label="`Todas as datas`"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="mt-2">
                <v-col cols="12" md="3">
                  <v-select
                    dense
                    outlined
                    prepend-icon="fas fa-shipping-fast"
                    label="Transportadoras"
                    v-model="filtros.cod_transportadora"
                    :items="dadosTransportadoraPersysAPI"
                    item-text="transportadora"
                    item-value="codigo"
                    @click:clear="filtros.cod_transportadora = null"
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    dense
                    outlined
                    prepend-icon="fas fa-shipping-fast"
                    label="Subcanal"
                    v-model="filtros.subCanal"
                    :items="filtros.subCanais"
                    item-text="nomeOriginal"
                    item-value="nomeOriginal"
                    @click:clear="filtros.subCanal = null"
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    dense
                    outlined
                    prepend-icon="fas fa-shipping-fast"
                    label="Estado"
                    v-model="filtros.estado"
                    :items="filtros.estados"
                    item-text="transportadora"
                    item-value="transportadora"
                    @click:clear="filtros.estado = null"
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    dense
                    outlined
                    prepend-icon="fas fa-pallet"
                    label="Difal"
                    v-model="filtros.difal"
                    :items="opcDifal"
                    item-text="descricao"
                    item-value="codigo"
                    @click:clear="filtros.difal = null"
                    clearable
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    dense
                    outlined
                    prepend-icon="fas fa-fingerprint"
                    label="Número Pedido"
                    type="text"
                    autocomplete="off"
                    v-model="filtros.codigo"
                    @click:clear="filtros.codigo = null"
                    clearable
                    class="inputPrice"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    dense
                    outlined
                    prepend-icon="fas fa-map-marker-alt"
                    label="Cep Origem"
                    type="number"
                    autocomplete="off"
                    v-model="filtros.cep_origem"
                    @click:clear="filtros.cep_origem = null"
                    clearable
                    class="inputPrice"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    dense
                    outlined
                    prepend-icon="fas fa-map-marker-alt"
                    label="Cep Destino"
                    type="number"
                    autocomplete="off"
                    v-model="filtros.cep_destino"
                    @click:clear="filtros.cep_destino = null"
                    clearable
                    class="inputPrice"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    dense
                    outlined
                    prepend-icon="fas fa-dolly"
                    label="Rastreio"
                    type="text"
                    autocomplete="off"
                    v-model="filtros.numero_rastreio"
                    @click:clear="filtros.numero_rastreio = null"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    dense
                    outlined
                    prepend-icon="fas fa-clock"
                    label="Prazo"
                    type="number"
                    autocomplete="off"
                    v-model="filtros.prazo"
                    @click:clear="filtros.prazo = null"
                    clearable
                    class="inputPrice"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-dialog
                    ref="modal_dt_prevista"
                    v-model="modal.dt_prevista"
                    :return-value.sync="filtros.dt_prevista"
                    persistent
                    width="290px"
                    :retain-focus="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        prepend-icon="fas fa-calendar-alt"
                        label="Data Prevista"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :value="formatDateDtPrevista"
                        @click:clear="filtros.dt_prevista = null"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="filtros.dt_prevista" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal.dt_prevista = false"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.modal_dt_prevista.save(filtros.dt_prevista)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" md="3">
                  <v-dialog
                    ref="modal_dt_considerada"
                    v-model="modal.dt_considerada"
                    :return-value.sync="filtros.dt_considerada"
                    persistent
                    width="290px"
                    :retain-focus="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        prepend-icon="fas fa-calendar-alt"
                        label="Data Considerada"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :value="formatDateDtConsiderada"
                        @click:clear="filtros.dt_considerada = null"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="filtros.dt_considerada" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal.dt_considerada = false"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.modal_dt_considerada.save(filtros.dt_considerada)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" md="3">
                  <v-dialog
                    ref="modal_dt_ultima_posicao"
                    v-model="modal.dt_ultima_posicao"
                    :return-value.sync="filtros.dt_ultima_posicao"
                    persistent
                    width="290px"
                    :retain-focus="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        prepend-icon="fas fa-calendar-alt"
                        label="Data Última Posição"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :value="formatDateDtUltimaPosicao"
                        @click:clear="filtros.dt_ultima_posicao = null"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="filtros.dt_ultima_posicao" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal.dt_ultima_posicao = false"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.modal_dt_ultima_posicao.save(filtros.dt_ultima_posicao)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" md="3">
                  <v-dialog
                    ref="modal_dt_entrega"
                    v-model="modal.dt_entrega"
                    :return-value.sync="filtros.dt_entrega"
                    persistent
                    width="290px"
                    :retain-focus="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        prepend-icon="fas fa-calendar-alt"
                        label="Data Entrega"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :value="formatDateDtEntrega"
                        @click:clear="filtros.dt_entrega = null"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="filtros.dt_entrega" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal.dt_entrega = false"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.modal_dt_entrega.save(filtros.dt_entrega)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right" style="margin-top: 280px;">
            <v-btn
              depressed
              color="error"
              class="mr-2"
              @click="modal.filtro = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="success"
              @click="limparFiltros"
            >
              Limpar Filtros
            </v-btn>
            <v-btn
              depressed
              color="success"
              @click="buscarObjetos"
            >
            <v-progress-circular
              indeterminate
              color="white"
              :size="20"
              class="mr-2"
              v-if="pesquisando"
            ></v-progress-circular>
              Pesquisar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Fim filtro -->

    <!-- Início filtro empresa 5-->
    <v-dialog
      v-model="modal.filtroEmpresaCinco"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="#018656" dark>
          <v-app-bar-nav-icon @click="modal.filtroEmpresaCinco = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
          <v-icon class="ml-2"> fas fa-boxes </v-icon>
          <v-toolbar-title class="pl-4"> Filtrar objetos </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="limparFiltrosEmpresaCinco">
            <v-icon>fas fa-broom</v-icon>
          </v-app-bar-nav-icon>
          <v-app-bar-nav-icon
            @click="
              (grid.objetos.paginaAtual = 1),
                filtroModalObjetos(),
                (modal.filtroEmpresaCinco = false)
            "
          >
            <v-icon>fas fa-search</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>
        <v-card-text>
          <v-col class="pt-6">
            <v-form>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    dense
                    outlined
                    prepend-icon="fas fa-fingerprint"
                    label="Pedido"
                    type="number"
                    autocomplete="off"
                    @click:clear="filtrosEmpresaCinco.numeroPedido = ''"
                    v-model="filtrosEmpresaCinco.numeroPedido"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    dense
                    outlined
                    prepend-icon="fas fa-shipping-fast"
                    label="Transportadoras"
                    v-model="filtrosEmpresaCinco.transportadoraNome"
                    @click:clear="filtrosEmpresaCinco.transportadoraNome = ''"
                    :items="transportadoras"
                    item-text="transportadora"
                    item-value="codigo"
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    dense
                    outlined
                    prepend-icon="fas fa-dolly"
                    label="Rastreio"
                    type="text"
                    autocomplete="off"
                    v-model="filtrosEmpresaCinco.numeroRastreio"
                    @click:clear="filtrosEmpresaCinco.numeroRastreio = ''"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    dense
                    outlined
                    prepend-icon="fas fa-clock"
                    label="Prazo"
                    type="number"
                    autocomplete="off"
                    v-model="filtrosEmpresaCinco.prazoEntrega"
                    @click:clear="filtrosEmpresaCinco.prazoEntrega = ''"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-dialog
                    ref="modal_dt_considerada"
                    v-model="modal.dt_considerada"
                    :return-value.sync="filtrosEmpresaCinco.dataConsiderada"
                    persistent
                    width="290px"
                    :retain-focus="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        prepend-icon="fas fa-calendar-alt"
                        label="Dt. Considerada"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :value="formatDateDataConsiderada"
                        clearable
                        @click:clear="filtrosEmpresaCinco.dataConsiderada = ''"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="filtrosEmpresaCinco.dataConsiderada" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal.dt_considerada = false"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.modal_dt_considerada.save(
                            filtrosEmpresaCinco.dataConsiderada
                          )
                        "
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" md="3">
                  <v-dialog
                    ref="modal_dt_prevista"
                    v-model="modal.dt_prevista"
                    :return-value.sync="filtrosEmpresaCinco.dataPrevista"
                    persistent
                    width="290px"
                    :retain-focus="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        prepend-icon="fas fa-calendar-alt"
                        label="Dt. Prevista"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :value="formatDateDataPrevista"
                        clearable
                        @click:clear="filtrosEmpresaCinco.dataPrevista = ''"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="filtrosEmpresaCinco.dataPrevista" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal.dt_prevista = false"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.modal_dt_prevista.save(filtrosEmpresaCinco.dataPrevista)
                        "
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" md="3">
                  <v-dialog
                    ref="modal_dt_entrega"
                    v-model="modal.dt_entrega"
                    :return-value.sync="filtrosEmpresaCinco.dataEntrega"
                    persistent
                    width="290px"
                    :retain-focus="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        prepend-icon="fas fa-calendar-alt"
                        label="Dt. Entrega"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :value="formatDateDataEntrega"
                        @click:clear="filtrosEmpresaCinco.dataEntrega = ''"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="filtrosEmpresaCinco.dataEntrega" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal.dt_entrega = false"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.modal_dt_entrega.save(filtrosEmpresaCinco.dataEntrega)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    dense
                    outlined
                    prepend-icon="fas fa-map-marker-alt"
                    label="Cep Origem"
                    type="text"
                    autocomplete="off"
                    v-model="filtrosEmpresaCinco.remetenteCep"
                    @click:clear="filtrosEmpresaCinco.remetenteCep = ''"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    dense
                    outlined
                    prepend-icon="fas fa-map-marker-alt"
                    label="Cep Destino"
                    type="text"
                    autocomplete="off"
                    v-model="filtrosEmpresaCinco.destinatarioCep"
                    @click:clear="filtrosEmpresaCinco.destinatarioCep = ''"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-dialog
                    ref="modal_dt_ultima_posicao"
                    v-model="modal.dt_ultima_posicao"
                    :return-value.sync="filtrosEmpresaCinco.dataUltimaPosicao"
                    persistent
                    width="290px"
                    :retain-focus="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        prepend-icon="fas fa-calendar-alt"
                        label="Dt. Últ. Mov."
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :value="formatDateDataUltPosicao"
                        @click:clear="filtrosEmpresaCinco.dataUltimaPosicao = ''"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filtrosEmpresaCinco.dataUltimaPosicao"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal.dt_ultima_posicao = false"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.modal_dt_ultima_posicao.save(
                            filtrosEmpresaCinco.dataUltimaPosicao
                          )
                        "
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    readonly
                    dense
                    outlined
                    prepend-icon="fas fa-pallet"
                    label="Últ. Mov."
                    v-model="filtrosEmpresaCinco.ultimaPosicao"
                    @click:clear="filtrosEmpresaCinco.ultimaPosicao = ''"
                    clearable
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-select
                    dense
                    outlined
                    prepend-icon="fas fa-file"
                    :items="filtrosEmpresaCinco.opcoesDifal"
                    label="Difal"
                    v-model="filtrosEmpresaCinco.difal"
                    @click:clear="filtrosEmpresaCinco.difal = 'Todos'"
                    clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    dense
                    outlined
                    prepend-icon="fas fa-file"
                    label="SubCanal"
                    v-model="filtrosEmpresaCinco.subCanal"
                    @click:clear="filtrosEmpresaCinco.subCanal = ''"
                    :items="subcanais"
                    item-text="text"
                    item-value="value"
                    clearable
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Fim filtro -->

    <!-- Início stop embalamento -->
    <v-dialog
      v-model="modal.stopEmbalamento"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="#018656" dark>
          <v-icon class="ml-2"> fas fa-boxes </v-icon>
          <v-toolbar-title class="pl-4"> Stop Embalamento </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="modal.stopEmbalamento = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>

        <v-card>
          <div>
            <v-toolbar-title class="pl-4" style="padding-top: 20px; padding-right: 20px; justify-content: space-between; width: 100%; display: flex">
              Número do pedido: {{ stopEmbalamento.numero_pedido }} - Valor produtos: {{ stopEmbalamento.valor_produtos }} - Valor frete: {{ stopEmbalamento.valor_frete }} - Base: {{ stopEmbalamento.base_transportadora }}
            </v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-divider></v-divider>
            <v-toolbar-title class="pl-4" style="padding-top: 20px; padding-right: 20px; color: black">
              Produtos
            </v-toolbar-title>
            <v-row class="modalConferencia mt-2">
              <v-col class="col-6">
                <v-data-table
                  dense
                  :header-props="{ sortIcon: null }"
                  :headers="this.grid.produtosEmbalamento.cabecalho"
                  :items="this.grid.produtosEmbalamento.items"
                  hide-default-footer
                  class="elevation-1"
                  item-key="codigo"
                >
                </v-data-table>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="mt-2" style="margin-left: 8px">
              <div>
                <v-col class="pl-0">
                  <v-text-field
                    dense
                    outlined
                    readonly
                    label="Preço"
                    v-model="this.stopEmbalamento.preco"
                    type="text"
                    style="width: 200px"
                  ></v-text-field>
                </v-col>
              </div>
            </v-row>
            <v-toolbar-title class="pl-4" style="padding-top: 10px; padding-bottom: 20px; padding-right: 20px; color: black" >
              Embalamento
            </v-toolbar-title>
            <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
              <div v-for="linha, index in linhas" :key="linha.index">
                <v-row style="margin-left: 1px" class="alinhamentoEmbalamento">
                  <div>
                    <v-col>
                      <v-text-field-percent
                        v-model="linha.altura"
                        v-bind:label="altura"
                        v-bind:properties="{
                          readonly: false,
                          disabled: false,
                          dense: true,
                          outlined: true,
                          placeholder: '',
                        }"
                        v-bind:options="{
                          locale: 'en-US',
                          length: 4,
                          precision: 2,
                          empty: null,
                        }"
                      />
                    </v-col>
                  </div>
                  <div>
                    <v-col>
                      <v-text-field-percent
                        v-model="linha.largura"
                        v-bind:label="largura"
                        v-bind:properties="{
                          readonly: false,
                          disabled: false,
                          dense: true,
                          outlined: true,
                          placeholder: '',
                        }"
                        v-bind:options="{
                          locale: 'en-US',
                          length: 4,
                          precision: 2,
                          empty: null,
                        }"
                      />
                    </v-col>
                  </div>
                  <div>
                    <v-col>
                      <v-text-field-percent
                        v-model="linha.comprimento"
                        v-bind:label="comprimento"
                        v-bind:properties="{
                          readonly: false,
                          disabled: false,
                          dense: true,
                          outlined: true,
                          placeholder: '',
                        }"
                        v-bind:options="{
                          locale: 'en-US',
                          length: 4,
                          precision: 2,
                          empty: null,
                        }"
                      />
                    </v-col>
                  </div>
                  <div>
                    <v-col>
                      <v-text-field-percent
                        v-model="linha.peso"
                        v-bind:label="peso"
                        v-bind:properties="{
                          readonly: false,
                          disabled: false,
                          dense: true,
                          outlined: true,
                          placeholder: '',
                        }"
                        v-bind:options="{
                          locale: 'en-US',
                          length: 4,
                          precision: 3,
                          empty: null,
                        }"
                      />

                    </v-col>
                  </div>
                  <div>
                    <v-col>
                      <v-text-field
                        dense
                        outlined
                        label="Quantidade"
                        v-model="linha.quantidade"
                        type="number"
                        style="width: 200px"
                      ></v-text-field>
                    </v-col>
                  </div>
                  <div v-if="index == linhas.length - 1">
                    <v-col style="margin-top: 12px">
                      <v-icon @click="addLinha()" small color="green">fas fa-plus</v-icon>
                    </v-col>
                  </div>
                  <div v-if="linhas.length > 1">
                    <v-col style="margin-top: 12px">
                      <v-icon @click="removeLinha(index)" small color="red">fas fa-trash</v-icon>
                    </v-col>
                  </div>

                </v-row>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="alignmentDialog">
            <v-btn class="ma-2" color="error" @click="(modal.stopEmbalamento = false, limparLinhas())">
              Cancelar
            </v-btn>
            <v-btn
              class="ma-2"
              color="success"
              @click="salvarEmbalamento()"
              :disabled="linhas[0].altura == 0 || linhas[0].largura == 0 || linhas[0].comprimento == 0 || linhas[0].peso == 0 || linhas[0].quantidade == 0"
            >
              Concluir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
    <!-- Fim stop embalamento -->

    <!-- Início detalhes_conferencia -->
    <v-dialog
      v-model="modal.detalhes_conferencia"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="#018656" dark dense fixed>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="closeModalDetalhesConferencia()">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>
        <v-card-text style="padding: 0;">
          <v-tabs
            v-model="tabIndex"
            flat
          >
            <v-tab
              v-for="(tab, index) in tabs"
              :key="index"
              style="text-transform: none;"
              v-if="index != 1 || tabIndexGrid != 0"
            >
              {{ tab }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabIndex">
            <v-tab-item>
              <v-card>
                <v-card-text>
                  <v-col class="pt-6">
                    <v-progress-circular
                      v-if="modal.loading"
                      indeterminate
                      color="primary"
                      width="2"
                      style="width: 100%"
                    ></v-progress-circular>
                    <v-row>
                      <v-col cols="4">
                        <div v-if="!objeto.habilita">
                          <v-select
                            prepend-icon="fas fa-bullhorn"
                            :items="itensReclamacao"
                            v-model="reclamacao.codigo"
                            label="Motivos para abrir Reclamação"
                            dense
                            outline
                          ></v-select>
                        </div>
                        <div v-else>
                          <v-text-field
                            dense
                            outlined
                            label="Protocolo de reclamação"
                            type="text"
                            autocomplete="off"
                            v-model="objeto.protocolo_reclamacao"
                            :disabled="objeto.habilita"
                            clearable
                            @click:append="
                              salvarProtocoloReclamacao(objeto.protocolo_reclamacao)
                            "
                          >
                            <template slot="append">
                              <v-icon>fas fa-save</v-icon>
                            </template>
                          </v-text-field>
                        </div>
                      </v-col>
                      <v-col cols="1">
                        <v-btn
                          v-if="!objeto.habilita"
                          title
                          color="info"
                          @click="criarReclamacao()"
                        >
                          <v-icon left>fas fa-save</v-icon>
                          Enviar
                        </v-btn>
                      </v-col>
                      <v-col cols="4"></v-col>
                      <v-col cols="3">
                        <v-text-field v-if="this.statusObjeto == 'E' && this.ultimaPosicao != 'N'"
                          dense
                          outlined
                          label="Nº rastreio"
                          type="text"
                          autocomplete="off"
                          v-model="objeto.numero_rastreio"
                        >
                        </v-text-field>
                        <v-text-field
                          dense
                          outlined
                          label="Nº rastreio"
                          type="text"
                          autocomplete="off"
                          v-model="objeto.numero_rastreio"
                          readonly
                          v-else
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <b>Enviado</b>
                        <v-row>
                          <v-col cols="12" style="padding-bottom: 0px">
                            Data postagem: {{ detalhes.enviado.data_postagem }}
                          </v-col>
                          <v-col
                            cols="12"
                            style="padding-bottom: 0px; padding-top: 0px"
                          >
                            Data considerada: {{ detalhes.enviado.data_considerada }}
                          </v-col>
                          <v-col
                            cols="12"
                            style="padding-bottom: 0px; padding-top: 0px"
                          >
                            de
                          </v-col>

                          <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;" v-if="this.statusObjeto == 'E' && this.ultimaPosicao != 'N'">
                            Cep: <input type="text" size="8" name="cep_de" id="cep_de" required v-model="detalhes.enviado.cep" />
                          </v-col>

                          <v-col
                            cols="12"
                            style="padding-bottom: 0px; padding-top: 0px"
                            v-else
                          >
                            Cep: {{ detalhes.enviado.cep }}
                          </v-col>
                          <v-col
                            cols="12"
                            style="padding-bottom: 0px; padding-top: 0px"
                          >
                            Cidade: {{ detalhes.enviado.cidade }}
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col>
                        <b>Para</b>
                        <v-row>
                          <v-col cols="12" style="padding-bottom: 0px">
                            Nome: {{ detalhes.para.nome }}
                          </v-col>

                          <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;" v-if="this.statusObjeto == 'E' && this.ultimaPosicao != 'N'">
                            Cep: <input type="text" size="8" name="cep_para" id="cep_para" required v-model="detalhes.para.cep" />
                          </v-col>

                          <v-col
                            cols="12"
                            style="padding-bottom: 0px; padding-top: 0px"
                            v-else
                          >
                            Cep: {{ detalhes.para.cep }}
                          </v-col>
                          <v-col
                            cols="12"
                            style="padding-bottom: 0px; padding-top: 0px"
                          >
                            Cidade: {{ detalhes.para.cidade }}
                          </v-col>
                          <v-col
                            cols="12"
                            style="padding-bottom: 0px; padding-top: 0px"
                          >
                            Bairro: {{ detalhes.para.bairro }}
                          </v-col>
                          <v-col
                            cols="12"
                            style="padding-bottom: 0px; padding-top: 0px"
                          >
                            Rua: {{ detalhes.para.rua }}
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col>
                        <b>Previsão de Entrega</b>
                        <v-col
                          cols="12"
                          style="
                            padding-bottom: 0px;
                            padding-top: 0px;
                            padding-left: 0px;
                          "
                        >
                          Data: {{ detalhes.previsao_de_entrega.data }}
                        </v-col>
                        <v-col
                          cols="12"
                          style="
                            padding-bottom: 0px;
                            padding-top: 0px;
                            padding-left: 0px;
                          "
                        >
                          Em domicilio: {{ detalhes.previsao_de_entrega.em_domicilio }}
                        </v-col>
                        <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px;" v-if="this.statusObjeto == 'E' && this.ultimaPosicao != 'N'">
                          Codigo Serviço: <input type="text" size="12" name="cod_servico" id="cod_servico" required v-model="detalhes.cod_servico" />
                        </v-col>

                        <v-col
                          cols="12"
                          style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px;"
                          v-else
                        >
                          Codigo Serviço: {{ detalhes.cod_servico }}
                        </v-col>

                      </v-col>
                      <v-col>
                        <b>Entregue em</b>
                        <v-col
                          cols="12"
                          style="
                            padding-bottom: 0px;
                            padding-top: 0px;
                            padding-left: 0px;
                          "
                        >
                          Data: {{ detalhes.entregue_em.data }}
                        </v-col>
                        <v-row>
                          <v-col>
                              <b>Ultima Movimentação</b>
                              <v-row>
                                  <v-col>
                                      <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px;">
                                          Motivo: {{ detalhes.ultima_posicao }}
                                      </v-col>
                                      <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px;">
                                          Data: {{ detalhes.dt_ultima_posicao }}
                                      </v-col>
                                  </v-col>
                              </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-btn
                      depressed
                      color="#018656"
                      class="mt-4 btnSalvar"
                      @click="save( objeto )"
                      style="margin: 20px 0 20px 0"
                      v-if="this.statusObjeto == 'E' && this.ultimaPosicao != 'N'"
                    >
                      Salvar
                    </v-btn>

                    <v-btn
                      depressed
                      color="#018656"
                      class="mt-4 btnSalvar"
                      @click="save( objeto )"
                      style="margin: 20px 0 20px 0"
                      disabled
                      v-else
                    >
                      Salvar
                    </v-btn>

                    <v-divider></v-divider>
                    <v-row v-if="detalhes.numero_danfe" style="margin-top: 10px">
                      <v-col>
                        <b>Numero Danfe</b>
                        <v-col
                          cols="12"
                          style="
                            padding-bottom: 0px;
                            padding-top: 0px;
                            padding-left: 0px;
                          "
                        >
                          Chave: {{ detalhes.numero_danfe }}
                        </v-col>
                        <v-col
                          cols="12"
                          style="
                            padding-bottom: 0px;
                            padding-top: 0px;
                            padding-left: 0px;
                          "
                        >
                          Order Id: {{ detalhes.order_id }}
                        </v-col>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <b>Rastreio do objeto</b>
                      </v-col>
                      <v-row v-if="grid.detalhes.items.length > 0">
                        <v-col cols="12" class="px-6">
                          <v-data-table
                            dense
                            :headers="grid.detalhes.cabecalho"
                            :items="grid.detalhes.items"
                            hide-default-footer
                            class="elevation-1"
                            :loading="grid.detalhes.carregando"
                            item-key="codigo"
                          >
                            <template #item.data_hora="{ item }"
                              >{{ formatarDataBR(item.data) }} {{ item.hora }}</template
                            >
                            <template #item.origem_cidade_uf_local="{ item }"
                              >{{ item.cidade }}
                              {{ item.cidade != null && item.uf != null ? "/" : "" }}
                              {{ item.uf }}
                              {{ item.local != null && item.uf != null ? "-" : "" }}
                              {{ item.local }}</template
                            >
                            <template #item.destino_cidade_uf_local="{ item }"
                              >{{ item.destino_cidade }}
                              {{
                                item.destino_cidade != null && item.destino_uf != null
                                  ? "/"
                                  : ""
                              }}
                              {{ item.destino_uf }}
                              {{
                                item.destino_local != null && item.destino_uf != null
                                  ? "-"
                                  : ""
                              }}
                              {{ item.destino_local }}</template
                            >
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <b>Reclamações</b>
                      </v-col>
                      <v-row v-if="grid.manifesto.items.length > 0">
                        <v-col cols="12" class="px-6">
                          <v-data-table
                            dense
                            :headers="grid.manifesto.cabecalho"
                            :items="grid.manifesto.items"
                            hide-default-footer
                            class="elevation-1"
                            :loading="grid.manifesto.carregando"
                            item-key="id"
                          >
                            <template #item.data="{ item }">{{
                              formatarDataBR(item.data)
                            }}</template>
                            <template v-slot:item.controls="{ item }">
                              <v-tooltip bottom color="primary">
                                <template v-slot:activator="{ on }">
                                  <v-app-bar-nav-icon
                                    @click="actionSend('send', objeto)"
                                    v-on="on"
                                  >
                                    <v-icon>fas fa-envelope</v-icon>
                                  </v-app-bar-nav-icon>
                                </template>
                                <span>Manifestação Email</span>
                              </v-tooltip>
                              <v-tooltip bottom color="error">
                                <template v-slot:activator="{ on }">
                                  <v-app-bar-nav-icon
                                    @click="actionSend('close', objeto)"
                                    v-on="on"
                                  >
                                    <v-icon>fas fa-hourglass-end</v-icon>
                                  </v-app-bar-nav-icon>
                                </template>
                                <span>Encerrar Reclamação</span>
                              </v-tooltip>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-row>
                  </v-col>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <v-card>
                  <div>
                    <v-row>
                      <v-col>
                        <v-toolbar-title
                          class="pl-4"
                          style="padding-top: 20px; justify-content: space-between; width: 100%; display: flex"
                        >
                          Número do pedido: {{ numeroPedido }} - Valor produtos:
                          {{ conferencia.valor_produtos }} - Valor frete:
                          {{ conferencia.valor_frete }}  - Base: {{ conferencia.base_transportadora }}
                          <br>
                          Transportadora: {{ conferencia.transportadoraNome }} - Subcanal: {{ conferencia.subCanal }} - UF: {{ conferencia.destinatarioEstado }}

                        </v-toolbar-title>
                      </v-col>
                      <v-col class="mt-3 mr-4 text-right">
                        <v-btn color="success" depressed @click="reprocessar()">Reprocessar</v-btn>
                      </v-col>
                    </v-row>
                  </div>
                  <v-spacer></v-spacer>

                  <v-card-text>
                    <v-divider></v-divider>
                    <v-row class="mt-2" style="margin-left: 8px">
                      <div>
                        <v-col class="pl-0">
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Diferença Venda"
                            v-model="this.conferencia.diferenca_venda"
                            type="text"
                            style="width: 300px"
                            :class="
                              this.conferencia.diferenca_venda < 0
                                ? 'text-negative'
                                : ''
                            "
                          ></v-text-field>
                        </v-col>
                      </div>

                      <div>
                        <v-col class="pl-0" style="width: 340px">
                        </v-col>
                      </div>

                      <div>
                        <v-col class="pl-0">
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Diferença Custo"
                            v-model="this.conferencia.diferenca_custo"
                            type="text"
                            style="width: 300px"
                            :class="
                              this.conferencia.diferenca_custo < 0
                                ? 'text-negative'
                                : ''
                            "
                          ></v-text-field>
                        </v-col>
                      </div>

                      <div>
                        <v-col>
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Diferença Peso"
                            v-model="this.conferencia.diferenca_peso"
                            type="text"
                            style="width: 300px"
                            :class="
                              this.conferencia.diferenca_custo < 0
                                ? 'text-negative'
                                : ''
                            "
                          ></v-text-field>
                        </v-col>
                      </div>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="modalConferencia">
                      <div>
                        <v-col class="inputModalConferencia">
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Valor Frete Pedido"
                            v-model="this.conferencia.valor_frete"
                            type="text"
                            style="width: 300px"
                          ></v-text-field>
                        </v-col>
                      </div>

                      <div>
                        <v-col class="inputModalConferencia">
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Total Frete Cotação"
                            v-model="this.conferencia.total_frete_conferencia"
                            type="text"
                            style="width: 300px"
                          ></v-text-field>
                        </v-col>

                        <v-col class="inputModalConferencia">
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Total Peso Cotação"
                            v-model="this.conferencia.total_peso_conferencia"
                            type="text"
                            style="width: 300px"
                          ></v-text-field>
                        </v-col>

                        <v-col class="inputModalConferencia">
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Custo Frete"
                            v-model="this.conferencia.custo_frete"
                            type="text"
                            style="width: 300px"
                          ></v-text-field>
                        </v-col>

                        <v-col class="inputModalConferencia">
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Difal Frete"
                            v-model="this.conferencia.difal_frete"
                            type="text"
                            style="width: 300px"
                          ></v-text-field>
                        </v-col>

                        <v-col class="inputModalConferencia">
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Ganho Frete"
                            v-model="this.conferencia.ganho_frete"
                            type="text"
                            style="width: 300px"
                          ></v-text-field>
                        </v-col>

                        <v-col class="inputModalConferencia">
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Taxa"
                            v-model="this.conferencia.taxa_frete"
                            type="text"
                            style="width: 300px"
                          ></v-text-field>
                        </v-col>

                        <v-col class="inputModalConferencia">
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Lucro Frete"
                            v-model="this.conferencia.lucro_frete"
                            type="text"
                            style="width: 300px"
                          ></v-text-field>
                        </v-col>
                      </div>

                      <div style="margin-top: 65px;">
                        <v-col class="inputModalConferencia">
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Embarque Peso"
                            v-model="this.conferencia.embarque_peso"
                            type="text"
                            style="width: 300px"
                          ></v-text-field>
                        </v-col>

                        <v-col class="inputModalConferencia">
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Embarque Custo Frete"
                            v-model="this.conferencia.embarque_custo_frete"
                            type="text"
                            style="width: 300px"
                          ></v-text-field>
                        </v-col>

                        <v-col class="inputModalConferencia" style="margin-top: 200px">
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Lucro Embarque"
                            v-model="this.conferencia.lucro_embarque"
                            type="text"
                            style="width: 300px"
                            :class="
                              this.conferencia.lucro_embarque < 0
                                ? 'text-negative'
                                : ''
                            "
                          ></v-text-field>
                        </v-col>
                      </div>

                      <div style="margin-top: 0px;">
                        <v-col class="inputModalConferencia">
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Número Dacte"
                            v-model="this.conferencia.numero_dacte"
                            type="text"
                            style="width: 300px"
                          ></v-text-field>
                        </v-col>
                        <v-col class="inputModalConferencia">
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Peso Pago"
                            v-model="this.conferencia.peso_pago"
                            type="text"
                            style="width: 300px"
                          ></v-text-field>
                        </v-col>
                        <v-col class="inputModalConferencia">
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Custo Frete Pago"
                            v-model="this.conferencia.custo_frete_pago"
                            type="text"
                            style="width: 300px"
                          ></v-text-field>
                        </v-col>

                        <v-col class="inputModalConferencia" style="margin-top: 200px;">
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Lucro Final"
                            v-model="this.conferencia.lucro_final"
                            type="text"
                            style="width: 300px"
                            :class="
                              this.conferencia.lucro_final < 0
                                ? 'text-negative'
                                : ''
                            "
                          ></v-text-field>
                        </v-col>
                      </div>

                      <div style="margin-top: 131px;">
                        <v-col class="inputModalConferencia">
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Custo Frete Tabela"
                            v-model="this.conferencia.custo_frete_tabela"
                            type="text"
                            style="width: 300px"
                          ></v-text-field>
                        </v-col>

                        <v-col class="inputModalConferencia" style="margin-top: 200px;">
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Divergência Tabela"
                            v-model="this.conferencia.divergencia_tabela"
                            type="text"
                            style="width: 300px"
                            :class="
                              this.conferencia.diferenca_custo < 0
                                ? 'text-negative'
                                : ''
                            "
                          ></v-text-field>
                        </v-col>
                      </div>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="modalConferencia mt-2 ">
                      <v-col class="col-2" style="margin-right: 12px">
                        <v-text-field
                          dense
                          outlined
                          readonly
                          label="Cálculo Reverso"
                          v-model="this.conferencia.calculo_reverso"
                          type="text"
                          style="width: 320px"
                        ></v-text-field>
                      </v-col>

                      <v-col class="col-6">
                        <v-text-field
                          dense
                          outlined
                          readonly
                          label="Mensagem"
                          v-model="this.conferencia.mensagem"
                          type="text"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="modalConferencia mt-2">
                      <v-col class="col-12">
                        <v-card>
                          <v-card-title class="text-h7 primary" style="color: white; justify-content: center; height: 35px; padding: 0;">
                            Produtos
                          </v-card-title>
                          <v-card-text class="mt-4">
                            <v-data-table
                              dense
                              :headers="this.grid.produtos.cabecalho"
                              :items="this.grid.produtos.items"
                              hide-default-footer
                              class="elevation-1"
                              item-key="codigo"
                            >
                            </v-data-table>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row class="modalConferencia mt-2">
                      <v-col class="col-12">
                        <v-card>
                          <v-card-title class="text-h7 primary" style="color: white; height: 35px; padding: 0;">
                            <v-spacer></v-spacer>
                            <div style="margin-left: 150px">
                              Cotação - CEP destino: {{ dadosCotacao.cepDestino }} - Token: {{ dadosCotacao.token }}
                            </div>
                            <v-spacer></v-spacer>
                            <v-btn @click="modalRequisicaoCotacao = true" style="height: 27px; color: #1976D2; margin-right: 10px">Requisição</v-btn>
                          </v-card-title>
                          <v-card-text class="mt-4">
                            <v-data-table
                              dense
                              :headers="this.grid.cotacao.cabecalho"
                              :items="this.grid.cotacao.items"
                              hide-default-footer
                              class="elevation-1"
                              item-key="codigo"
                            >
                            </v-data-table>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row class="modalConferencia mt-2">
                      <v-col class="col-12">
                        <v-card>
                          <v-card-title class="text-h7 primary" style="color: white; justify-content: center; height: 35px; padding: 0;">
                            Retorno Cotação
                          </v-card-title>
                          <v-card-text class="mt-4">
                            <v-data-table
                              dense
                              :headers="this.grid.retornoCotacao.cabecalho"
                              :items="this.grid.retornoCotacao.items"
                              hide-default-footer
                              class="elevation-1"
                              item-key="codigo"
                              :item-class="itemStyle"
                            >
                            </v-data-table>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row class="modalConferencia mt-2">
                      <v-col class="col-12">
                        <v-card>
                          <v-card-title class="text-h7 primary" style="color: white; height: 35px; padding: 0;">
                            <v-spacer></v-spacer>
                            <div style="margin-left: 150px">
                              Embalamento - CEP destino: {{ dadosEmbalamento.cepDestino }} - Token: {{ dadosEmbalamento.token }}
                            </div>
                            <v-spacer></v-spacer>
                            <v-btn @click="modalRequisicaoEmbalamento = true" style="height: 27px; color: #1976D2; margin-right: 10px">Requisição</v-btn>
                          </v-card-title>
                          <v-card-text class="mt-4">
                            <v-data-table
                              dense
                              :headers="this.grid.produtosEmbalamento.cabecalhoUS"
                              :items="this.grid.produtosEmbalamento.items"
                              hide-default-footer
                              class="elevation-1"
                              item-key="codigo"
                            >
                            </v-data-table>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row class="modalConferencia mt-2">
                      <v-col class="col-12">
                        <v-card>
                          <v-card-title class="text-h7 primary" style="color: white; justify-content: center; height: 35px; padding: 0;">
                            Retorno Embalamento
                          </v-card-title>
                          <v-card-text class="mt-4">
                            <v-data-table
                              dense
                              :headers="this.grid.retornoEmbalamento.cabecalho"
                              :items="this.grid.retornoEmbalamento.items"
                              hide-default-footer
                              class="elevation-1"
                              item-key="codigo"
                              :item-class="itemStyle"
                            >

                            <template #item.Base="{ item }">
                              <span
                                style="text-decoration: none;"
                                :style="item.Base == 2672 ? 'color: green; font-weight: bold;' : ''"
                              >
                                {{ item.Base }}
                              </span>
                            </template>

                            <template #item.custo="{ item }">
                              <span
                                style="text-decoration: none;"
                                :style="item.Base == 2672 ? 'color: green; font-weight: bold;' : ''"
                              >
                                {{ item.custo }}
                              </span>
                            </template>

                            <template #item.valor_frete="{ item }">
                              <span
                                style="text-decoration: none;"
                                :style="item.Base == 2672 ? 'color: green; font-weight: bold;' : ''"
                              >
                                {{ item.valor_frete }}
                              </span>
                            </template>

                            <template #item.peso="{ item }">
                              <span
                                style="text-decoration: none;"
                                :style="item.Base == 2672 ? 'color: green; font-weight: bold;' : ''"
                              >
                                {{ item.peso }}
                              </span>
                            </template>

                            <template #item.prazo_transporte="{ item }">
                              <span
                                style="text-decoration: none;"
                                :style="item.Base == 2672 ? 'color: green; font-weight: bold;' : ''"
                              >
                                {{ item.prazo_transporte }}
                              </span>
                            </template>
                            </v-data-table>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row class="modalConferencia mt-2">
                      <v-col class="col-12">
                        <v-card>
                          <v-card-title class="text-h7 primary" style="color: white; justify-content: center; height: 35px; padding: 0;">
                            Status
                          </v-card-title>
                          <v-card-text class="mt-4">
                            <v-row>
                              <v-col cols="12" style="padding-bottom: 0px">
                                <b>Número do pedido:</b> {{ dadosPedido.pedidoCanalVenda }}
                              </v-col>
                              <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                                <b>Código pedido ERP:</b> {{ dadosPedido.codigoPedidoAbacos }}
                              </v-col>
                              <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                                <b>Status:</b> {{ dadosPedido.statusPedido }}
                              </v-col>
                              <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                                <b>Emissão nota:</b> {{ dadosPedido.dataEmissaoNota }}
                              </v-col>
                              <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                                <b>Série nota:</b> {{ dadosPedido.serieNota }}
                              </v-col>
                              <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                                <b>Número nota:</b> {{ dadosPedido.numeroNota }}
                              </v-col>
                              <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                                <b>Chave nfe:</b> {{ dadosPedido.chaveNfe }}
                              </v-col>
                              <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                                <b>Código rastreio:</b> {{ dadosPedido.numeroObjeto }}
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Fim detalhes_conferencia -->

    <!-- inicio modal requisicao  -->
    <v-dialog
      v-model="modalRequisicaoCotacao"
      width="600"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Json Cotação
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="modalRequisicaoCotacao = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-card-title>

        <pre id="jsonCotacao" style="margin-top: 20px !important">
          {{ json.cotacao }}
        </pre>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="copiarJsonCotacao()"
          >
            Copiar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="modalRequisicaoEmbalamento"
      width="600"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Json Embalamento
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="modalRequisicaoEmbalamento = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-card-title>

        <pre id="jsonEmbalamento" style="margin-top: 20px !important">
          {{ json.embalamento }}
        </pre>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="copiarJsonEmbalamento()"
          >
            Copiar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- fim modal requisicao -->

    <!-- Início rastreio -->
    <v-dialog
      v-model="modal.rastreio"
      hide-overlay
      transition="dialog-bottom-transition"
      max-width="700px"
    >
      <v-card>
        <v-toolbar color="#018656" dark dense fixed>
          <v-icon class="ml-2"> fas fa-boxes </v-icon>
          <v-toolbar-title class="pl-4">
            Detalhes do rastreio ({{ objetoAtual }})
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="modal.rastreio = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>
        <v-card-text>
          <v-col class="pt-6">
            <v-progress-circular
              v-if="modal.loading"
              indeterminate
              color="primary"
              width="2"
              style="width: 100%"
            ></v-progress-circular>
            <v-timeline v-else dense clipped>
              <v-timeline-item
                class="mb-4"
                :color="idx == 0 ? 'green' : 'blue'"
                :icon-color="idx == 0 ? 'green lighten-2' : 'blue lighten-2'"
                small
                v-for="(rastreio, idx) in rastreios"
                :key="rastreio.codigo"
              >
                <v-row justify="space-between">
                  <v-col cols="12" style="padding: 0">
                    Evento: {{ rastreio.descricao }}
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    Tipo: {{ rastreio.tipo }} Status: {{ rastreio.status }}
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    Data: {{ formatarDataBR(rastreio.data) }}
                    {{ rastreio.hora }}
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Fim rastreio -->

    <!-- Início detalhes -->
    <v-dialog
      v-model="modal.detalhes"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="#018656" dark>
          <v-icon class="ml-2"> fas fa-boxes </v-icon>
          <v-toolbar-title class="pl-4">
            Detalhes do objeto ({{ objetoAtual }})
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="modal.detalhes = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>
        <v-card-text>
          <v-col class="pt-6">
            <v-progress-circular
              v-if="modal.loading"
              indeterminate
              color="primary"
              width="2"
              style="width: 100%"
            ></v-progress-circular>
            <v-row>
              <v-col cols="4">
                <div v-if="!objeto.habilita">
                  <v-select
                    prepend-icon="fas fa-bullhorn"
                    :items="itensReclamacao"
                    v-model="reclamacao.codigo"
                    label="Motivos para abrir Reclamação"
                    dense
                    outline
                  ></v-select>
                </div>
                <div v-else>
                  <v-text-field
                    dense
                    outlined
                    label="Protocolo de reclamação"
                    type="text"
                    autocomplete="off"
                    v-model="objeto.protocolo_reclamacao"
                    :disabled="objeto.habilita"
                    clearable
                    @click:append="
                      salvarProtocoloReclamacao(objeto.protocolo_reclamacao)
                    "
                  >
                    <template slot="append">
                      <v-icon>fas fa-save</v-icon>
                    </template>
                  </v-text-field>
                </div>
              </v-col>
              <v-col cols="1">
                <v-btn
                  v-if="!objeto.habilita"
                  title
                  color="info"
                  @click="criarReclamacao()"
                >
                  <v-icon left>fas fa-save</v-icon>
                  Enviar
                </v-btn>
              </v-col>
              <v-col cols="4"></v-col>
              <v-col cols="3">
                <v-text-field
                  dense
                  outlined
                  label="Nº rastreio"
                  type="text"
                  autocomplete="off"
                  v-model="objeto.numero_rastreio"
                  clearable
                  append-icon="fas fa-search"
                  @click:append="carregarDetalhesBusca(objeto)"
                >
                  <template
                    slot="append"
                    v-if="detalhes.numero_rastreio == detalhes.order_id"
                  >
                    <v-btn
                      outlined
                      @click="salvarNumeroRastreio(objeto.numero_rastreio)"
                    >
                      <v-icon left>fas fa-save</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <b>Enviado</b>
                <v-row>
                  <v-col cols="12" style="padding-bottom: 0px">
                    Data postagem: {{ detalhes.enviado.data_postagem }}
                  </v-col>
                  <v-col
                    cols="12"
                    style="padding-bottom: 0px; padding-top: 0px"
                  >
                    Data considerada: {{ detalhes.enviado.data_considerada }}
                  </v-col>
                  <v-col
                    cols="12"
                    style="padding-bottom: 0px; padding-top: 0px"
                  >
                    de
                  </v-col>
                  <v-col
                    cols="12"
                    style="padding-bottom: 0px; padding-top: 0px"
                  >
                    Cep: {{ detalhes.enviado.cep }}
                  </v-col>
                  <v-col
                    cols="12"
                    style="padding-bottom: 0px; padding-top: 0px"
                  >
                    Cidade: {{ detalhes.enviado.cidade }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <b>Para</b>
                <v-row>
                  <v-col cols="12" style="padding-bottom: 0px">
                    Nome: {{ detalhes.para.nome }}
                  </v-col>
                  <v-col
                    cols="12"
                    style="padding-bottom: 0px; padding-top: 0px"
                  >
                    Cep: {{ detalhes.para.cep }}
                  </v-col>
                  <v-col
                    cols="12"
                    style="padding-bottom: 0px; padding-top: 0px"
                  >
                    Cidade: {{ detalhes.para.cidade }}
                  </v-col>
                  <v-col
                    cols="12"
                    style="padding-bottom: 0px; padding-top: 0px"
                  >
                    Bairro: {{ detalhes.para.bairro }}
                  </v-col>
                  <v-col
                    cols="12"
                    style="padding-bottom: 0px; padding-top: 0px"
                  >
                    Rua: {{ detalhes.para.rua }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <b>Previsão de Entrega</b>
                <v-col
                  cols="12"
                  style="
                    padding-bottom: 0px;
                    padding-top: 0px;
                    padding-left: 0px;
                  "
                >
                  Data: {{ detalhes.previsao_de_entrega.data }}
                </v-col>
                <v-col
                  cols="12"
                  style="
                    padding-bottom: 0px;
                    padding-top: 0px;
                    padding-left: 0px;
                  "
                >
                  Em domicilio: {{ detalhes.previsao_de_entrega.em_domicilio }}
                </v-col>
              </v-col>
              <v-col>
                <b>Entregue em</b>
                <v-col
                  cols="12"
                  style="
                    padding-bottom: 0px;
                    padding-top: 0px;
                    padding-left: 0px;
                  "
                >
                  Data: {{ detalhes.entregue_em.data }}
                </v-col>
              </v-col>
            </v-row>
            <v-row v-if="detalhes.numero_danfe">
              <v-col>
                <b>Numero Danfe</b>
                <v-col
                  cols="12"
                  style="
                    padding-bottom: 0px;
                    padding-top: 0px;
                    padding-left: 0px;
                  "
                >
                  Data: {{ detalhes.numero_danfe }}
                </v-col>
                <v-col
                  cols="12"
                  style="
                    padding-bottom: 0px;
                    padding-top: 0px;
                    padding-left: 0px;
                  "
                >
                  Order Id: {{ detalhes.order_id }}
                </v-col>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <b>Rastreio do objeto</b>
              </v-col>
              <v-row v-if="grid.detalhes.items.length > 0">
                <v-col cols="12" class="px-6">
                  <v-data-table
                    dense
                    :headers="grid.detalhes.cabecalho"
                    :items="grid.detalhes.items"
                    hide-default-footer
                    class="elevation-1"
                    :loading="grid.detalhes.carregando"
                    item-key="codigo"
                  >
                    <template #item.data_hora="{ item }"
                      >{{ formatarDataBR(item.data) }} {{ item.hora }}</template
                    >
                    <template #item.origem_cidade_uf_local="{ item }"
                      >{{ item.cidade }}
                      {{ item.cidade != null && item.uf != null ? "/" : "" }}
                      {{ item.uf }}
                      {{ item.local != null && item.uf != null ? "-" : "" }}
                      {{ item.local }}</template
                    >
                    <template #item.destino_cidade_uf_local="{ item }"
                      >{{ item.destino_cidade }}
                      {{
                        item.destino_cidade != null && item.destino_uf != null
                          ? "/"
                          : ""
                      }}
                      {{ item.destino_uf }}
                      {{
                        item.destino_local != null && item.destino_uf != null
                          ? "-"
                          : ""
                      }}
                      {{ item.destino_local }}</template
                    >
                  </v-data-table>
                </v-col>
              </v-row>
            </v-row>
            <v-row>
              <v-col cols="12">
                <b>Reclamações</b>
              </v-col>
              <v-row v-if="grid.manifesto.items.length > 0">
                <v-col cols="12" class="px-6">
                  <v-data-table
                    dense
                    :headers="grid.manifesto.cabecalho"
                    :items="grid.manifesto.items"
                    hide-default-footer
                    class="elevation-1"
                    :loading="grid.manifesto.carregando"
                    item-key="id"
                  >
                    <template #item.data="{ item }">{{
                      formatarDataBR(item.data)
                    }}</template>
                    <template v-slot:item.controls="{ item }">
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on }">
                          <v-app-bar-nav-icon
                            @click="actionSend('send', objeto)"
                            v-on="on"
                          >
                            <v-icon>fas fa-envelope</v-icon>
                          </v-app-bar-nav-icon>
                        </template>
                        <span>Manifestação Email</span>
                      </v-tooltip>
                      <v-tooltip bottom color="error">
                        <template v-slot:activator="{ on }">
                          <v-app-bar-nav-icon
                            @click="actionSend('close', objeto)"
                            v-on="on"
                          >
                            <v-icon>fas fa-hourglass-end</v-icon>
                          </v-app-bar-nav-icon>
                        </template>
                        <span>Encerrar Reclamação</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-row>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Fim Detalhes -->

    <!-- Inicio Exportar Table -->
    <v-dialog v-model="modal.exportacao" width="750px">
      <v-card>
        <v-card-title style="justify-content: center">
          <span class="text-h5"
            >Selecione um intervalo de datas para a exportação do
            relatório.</span
          >
        </v-card-title>
        <v-card-text>
          <v-row class="filtro_data">
            <v-col cols="12" md="6" class="alinhamento">
              <v-dialog
                ref="modal_data_inicio"
                v-model="modal.data_inicio"
                :return-value.sync="filtros.data_inicio"
                persistent
                width="290px"
                :retain-focus="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    prepend-icon="fas fa-calendar-alt"
                    label="Data inicial"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="formatDateInicio"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker v-model="filtros.data_inicio" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal.data_inicio = false"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.modal_data_inicio.save(filtros.data_inicio)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" md="6" class="alinhamento">
              <v-dialog
                ref="modal_data_final"
                v-model="modal.data_final"
                :return-value.sync="filtros.data_final"
                persistent
                width="290px"
                :retain-focus="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    prepend-icon="fas fa-calendar-alt"
                    label="Data final"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="formatDateFinal"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker v-model="filtros.data_final" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal.data_final = false"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.modal_data_final.save(filtros.data_final)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="modal.exportacao = false">
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="(modal.exportacao = false), exportarDados()"
          >
            Exportar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Fim Exportar Table -->

    <!-- Inicio Loader baixar exportacao -->
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Exportando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Fim Loader baixar exportacao -->

    <!-- Inicio Loader reprocessar -->
    <v-dialog v-model="loadingReprocessar" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text style="padding-top: 7px">
          Reprocessando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Fim Loader reprocessar -->

    <!-- Início principal -->
    <v-card style="height: calc(88vh)">
      <v-toolbar flat>
        <v-icon>
          fas fa-boxes
        </v-icon>
        <v-toolbar-title class="pl-4">
          Listagem de Objetos - <small>N. {{ grid.objetos.totSize }} de registros</small>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="modal.filtro = true">
          <v-icon>fas fa-search</v-icon>
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon @click="modal.exportacao = true">
          <v-icon>fas fa-file-download</v-icon>
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon @click="fechar">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>

      <v-divider></v-divider>
      <v-card-text>
        <v-row dense>
          <v-col cols="2" class="alinhamento">
            <v-dialog
              ref="modal_data_inicio"
              v-model="modal.data_inicio"
              :return-value.sync="dataInicio"
              persistent
              width="290px"
              :retain-focus="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  prepend-icon="fas fa-calendar-alt"
                  label="Data inicial"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :value="formatDateInicioGrid"
                  @click:clear="dataInicio = null"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker v-model="dataInicio" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal.data_inicio = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  @click="$refs.modal_data_inicio.save(dataInicio)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
          </v-col>

          <v-col cols="2" class="alinhamento">
            <v-dialog
              ref="modal_data_final"
              v-model="modal.data_final"
              :return-value.sync="dataFinal"
              persistent
              width="290px"
              :retain-focus="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  prepend-icon="fas fa-calendar-alt"
                  label="Data final"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :value="formatDateFinalGrid"
                  @click:clear="dataFinal = null"
                  clearable
                  append-outer-icon="fas fa-search"
                  @click:append-outer="(grid.objetos.actualPage = 1, carregarObjetosEmpresaCinco(), carregarItemsReverso())"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dataFinal" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal.data_final = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  @click="$refs.modal_data_final.save(dataFinal)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col
            cols="6"
            class="px-6 py-6"
            style="padding-bottom: 0px !important;"
          >
            <v-text-field prepend-icon="fas fa-search" dense label="Pesquisar" type="text"
              autocomplete="off" v-model="search" clearable color="#018454"
            ></v-text-field>
          </v-col>

          <v-col
            cols="2"
            style="display: flex; justify-content: end;"
            v-if="tabIndexGrid == 4"
          >
            <v-btn
              color="success"
              @click="reprocessAll"
              :disabled="grid.objetos.carregando"
              >Reprocessar
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="mb-2" v-if="checkNameItems() == 'cabecalhoEmpresaCinco'">
          <v-col>
            <v-pagination color="#018454" v-model="grid.objetos.actualPage" :length="grid.objetos.totalPage"
              @input="changePage" :total-visible="7"></v-pagination>
          </v-col>
        </v-row>
        
        <v-row>
          <v-col class="px-6" style="margin-top: -30px;">
            <!-- Se for empresa normal -->
            <v-data-table
              v-if="checkNameItems() == 'cabecalho'"
              dense
              :headers="changeCabecalho()"
              :items="grid.objetos.items"
              :items-per-page="grid.objetos.porPagina"
              hide-default-footer
              class="elevation-1"
              :loading="grid.objetos.carregando"
              item-key="codigo"
              v-model="grid.objetos.itemSelecionado"
            >
              <template #item.numero_rastreio="{ item }">
                <a
                  @click="carregarDetalhes(item)"
                  style="text-decoration: none;"
                >
                  {{ item.numero_rastreio }}
                </a>
              </template>

              <template #item.reclamacao="{ item }">
                <div v-if="item.reclamacao == 1">
                  <a
                    @click="carregarManifestacoes(item)"
                    style="text-decoration: none;"
                  >
                    {{ "Sim" }}
                  </a>
                </div>
                <div v-else>
                  {{ item.reclamacao > 1 ? "SLA" : "" }}
                </div>
              </template>
              <template #item.alerta="{ item }">
                <v-icon v-if="item.alerta">fas fa-exclamation-triangle</v-icon>
              </template>
              <template
                v-for="header in grid.objetos.cabecalho.filter((header) =>
                  header.hasOwnProperty('formatter')
                )"
                v-slot:[`item.${header.value}`]="{ header, value }"
              >
                {{ header.formatter(value) }}
              </template>
            </v-data-table>
            <!-- Se for empresa 5 -->
            <v-tabs
            v-model="tabIndexGrid"
            flat
            v-else-if="checkNameItems() == 'cabecalhoEmpresaCinco'"
            >
              <v-tab
                v-for="(tab, index) in tabsGrid"
                :key="index"
                style="text-transform: none;"
                @click="tabItemsGrid(index)"
              >
                {{ tab.description }}
              </v-tab>
            </v-tabs>
            <v-data-table
              dense
              :headers="changeCabecalho()"
              :header-props="{ sortIcon: null }"
              :items="grid.objetos.items"
              :items-per-page="grid.objetos.porPagina"
              :key="updateGrid"
              :search="search"
              hide-default-footer
              class="elevation-1"
              :loading="grid.objetos.carregando"
              item-key="codigo"
              v-model="grid.objetos.itemSelecionado"
              height="52vh"
              fixed-header
            >
              <!-- <template v-slot:header.numeroRastreioStatus="{ header }">
                <div class="alinhamentoHeader">
                  <v-icon small>fa-map-marker-alt</v-icon>Rastreio
                </div>
              </template> -->

              <template v-slot:header.calcVenda="{ header }">
                <div class="alinhamentoHeader">
                  <v-icon small>fa-dollar-sign</v-icon>Venda
                </div>
              </template>

              <template v-slot:header.calcEmbarque="{ header }">
                <div class="alinhamentoHeader">
                  <v-icon small>fa-truck</v-icon>Embarque
                </div>
              </template>

              <template v-slot:header.calcAuditoria="{ header }">
                <div class="alinhamentoHeader">
                  <v-icon small>fa-file-invoice</v-icon>Auditoria
                </div>
              </template>

              <template v-slot:header.calcTabela="{ header }">
                <div class="alinhamentoHeader">
                  <v-icon small>fa-table</v-icon>
                  Tabela
                </div>
              </template>

              <template v-slot:header.calcPeso="{ header }">
                <div class="alinhamentoHeader">
                  <v-icon small>fa-weight</v-icon>≠ Peso
                </div>
              </template>

              <template v-slot:header.itemStatus="{ header }" v-if="3>2">
                <div class="alinhamentoHeader">
                  <v-icon small>fa-exclamation-circle</v-icon>Status
                </div>
              </template>

              <template #item.numeroPedido="{ item }" v-if="this.tabIndexGrid == 3">
                <span @click="carregarEmbalamento(item)" style="cursor: pointer">
                  {{ item.numeroPedido }}
                </span>
              </template>

              <template #item.dataVenda="{ item }" v-if="this.tabIndexGrid == 3">
                <span @click="carregarEmbalamento(item)" style="cursor: pointer">
                  {{ item.dataVenda }}
                </span>
              </template>

              <template #item.dataPrevista="{ item }" v-if="this.tabIndexGrid == 3">
                <span @click="carregarEmbalamento(item)" style="cursor: pointer">
                  {{ item.dataPrevista }}
                </span>
              </template>

              <template #item.dataUltimaPosicao="{ item }" v-if="this.tabIndexGrid == 3">
                <span @click="carregarEmbalamento(item)" style="cursor: pointer">
                  {{ item.dataUltimaPosicao }}
                </span>
              </template>

              <template #item.transportadoraNome="{ item }" v-if="this.tabIndexGrid == 3">
                <span @click="carregarEmbalamento(item)" style="cursor: pointer">
                  {{ item.transportadoraNome }}
                </span>
              </template>

              <template #item.servicoEntrega="{ item }" v-if="this.tabIndexGrid == 3">
                <span @click="carregarEmbalamento(item)" style="cursor: pointer">
                  {{ item.servicoEntrega }}
                </span>
              </template>

              <template #item.subCanal="{ item }" v-if="this.tabIndexGrid == 3">
                <span @click="carregarEmbalamento(item)" style="cursor: pointer">
                  {{ item.subCanal }}
                </span>
              </template>

              <template #item.destinatarioEstado="{ item }" v-if="this.tabIndexGrid == 3">
                <span @click="carregarEmbalamento(item)" style="cursor: pointer">
                  {{ item.destinatarioEstado }}
                </span>
              </template>

              <template #item.entregaDomicilio="{ item }" v-if="this.tabIndexGrid == 3">
                <span @click="carregarEmbalamento(item)" style="cursor: pointer">
                  {{ item.entregaDomicilio }}
                </span>
              </template>

              <template #item.calcPeso="{ item }" >
                <span class="alinhamentoRow" :style="getColorPeso(item)"  @click="carregarDetalhes(item, 1)">
                  {{ item.calcPeso }}
                </span>
              </template>

              <template #item.calcVenda="{ item }">
                <span class="alinhamentoRow" :style="getColorVenda(item)"  @click="carregarDetalhes(item, 1)">
                  {{ item.calcVenda }}%
                </span>
              </template>

              <template #item.calcTabela="{ item }">
                <span class="alinhamentoRow" :style="getColorTabela(item)"  @click="carregarDetalhes(item, 1)">
                  {{ item.calcTabela }}
                </span>
              </template>

              <template #item.calcAuditoria="{ item }">
                <span class="alinhamentoRow" :style="getColorAuditoria(item)"  @click="carregarDetalhes(item, 1)">
                  {{ item.calcAuditoria }}%
                </span>
              </template>

              <template #item.calcEmbarque="{ item }">
                <span class="alinhamentoRow" :style="getColorEmbarqueGrid(item)"  @click="carregarDetalhes(item, 1)">
                  {{ item.calcEmbarque }}%
                </span>
              </template>

              <template #item.itemStatus="{ item }">
                <span style="text-decoration: none">
                  {{ item.itemStatus }}
                </span>
              </template>


              <template #item.numeroRastreioStatus="{ item }">
                <span
                  @click="carregarDetalhes(item)"
                  style="cursor: pointer;"
                  :style="getColorRastreio(item)"
                >
                  {{ item.numeroRastreioStatus}}
                </span>
              </template>

              <template #item.numeroRastreio="{ item }">
                <span
                  @click="carregarDetalhes(item)"
                  style="cursor: pointer;"
                  :style="getColorRastreio(item)"
                >
                  {{ item.numeroRastreio}}
                </span>
              </template>

              <template #item.valorFrete="{ item }">
                <span style="text-decoration: none;">
                  {{
                    item.valorFrete >= 0 && item.valorFrete != null
                      ? item.valorFrete.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                        })
                      : "0,00"
                  }}
                </span>
              </template>

              <template #item.detTotalFrete="{ item }">
                <span style="text-decoration: none;">
                  {{
                    item.detTotalFrete >= 0 && item.detTotalFrete != null
                      ? item.detTotalFrete.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                        })
                      : "0,00"
                  }}
                </span>
              </template>

              <template #item.detValorFrete="{ item }">
                <span style="text-decoration: none;">
                  {{
                    item.detValorFrete >= 0 && item.detValorFrete != null
                      ? item.detValorFrete.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                        })
                      : "0,00"
                  }}
                </span>
              </template>

              <template #item.detDifalFrete="{ item }">
                <span style="text-decoration: none;">
                  {{
                    item.detDifalFrete >= 0 && item.detDifalFrete != null
                      ? item.detDifalFrete.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                        })
                      : "0,00"
                  }}
                </span>
              </template>

              <template
                #item.percDifal="{ item }"
                :style="item.percDifal < 0 ? 'color: red' : ''"
              >
                <span style="text-decoration: none;">
                  {{
                    item.percDifal >= 0 && item.percDifal != null
                      ? item.percDifal.replace(".", ",") + "%"
                      : item.percDifal
                  }}
                </span>
              </template>

              <template
                #item.percGanho="{ item }"
                :style="item.percGanho < 0 ? 'color: red' : ''"
              >
                <span style="text-decoration: none;">
                  {{
                    item.percGanho >= 0 && item.percGanho != null
                      ? item.percGanho.replace(".", ",") + "%"
                      : item.percGanho
                  }}
                </span>
              </template>

              <template #item.percEmbarque="{ item }">
                <span
                  style="text-decoration: none;"
                  :style="item.percEmbarque < 0 ? 'color: red' : ''"
                >
                  {{
                    typeof (item.percEmbarque, "number") &&
                    item.percEmbarque != "Aguardando embarque" &&
                    item.percEmbarque != "Aguardando cotação"
                      ? item.percEmbarque.replace(".", ",") + "%"
                      : item.percEmbarque
                  }}
                </span>
              </template>

              <template #item.lucroFinal="{ item }">
                <span
                  style="text-decoration: none;"
                  :style="item.lucroFinal < 0 ? 'color: red' : ''"
                >
                  {{
                    typeof (item.lucroFinal, "number")
                      ? item.lucroFinal.replace(".", ",")
                      : item.lucroFinal
                  }}
                </span>
              </template>

              <template #item.percLucroFrete="{ item }">
                <span
                  style="text-decoration: none;"
                  :style="item.percLucroFrete < 0 ? 'color: red' : ''"
                >
                  {{
                    typeof (item.lucroFinal, "number") &&
                    item.percLucroFrete != "Aguardando cotação" &&
                    item.percLucroFrete != "Aguardando conemb"
                      ? item.percLucroFrete.replace(".", ",") + "%"
                      : item.percLucroFrete
                  }}
                </span>
              </template>

              <template #item.detGanhoFrete="{ item }">
                <span style="text-decoration: none;">
                  {{
                    item.detGanhoFrete >= 0 && item.detGanhoFrete != null
                      ? item.detGanhoFrete.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                        })
                      : "0,00"
                  }}
                </span>
              </template>

              <template #item.reclamacao="{ item }">
                <div v-if="item.reclamacao == 1">
                  <a
                    @click="carregarManifestacoes(item)"
                    style="text-decoration: none;"
                  >
                    {{ "Sim" }}
                  </a>
                </div>
                <div v-else>
                  {{ item.reclamacao > 1 ? "SLA" : "" }}
                </div>
                <!-- {{ (item.reclamacao) ? (item.reclamacao == 1) ? "Sim" : "SLA" : "" }} -->
              </template>
              <template #item.alerta="{ item }">
                <v-icon v-if="item.alerta">fas fa-exclamation-triangle</v-icon>
              </template>
              <!-- This template looks for headers with formatters and executes them -->
              <template
                v-for="header in grid.objetos.cabecalho.filter((header) =>
                  header.hasOwnProperty('formatter')
                )"
                v-slot:[`item.${header.value}`]="{ header, value }"
              >
                {{ header.formatter(value) }}
              </template>

              <template #item.divergenciaValor="{ item }">
                <td :style="item.divergenciaValor < 0 ? 'color: red' : ''">
                  {{ item.divergenciaValor }}
                </td>
              </template>

              <template #item.divergenciaPagamento="{ item }">
                <td :style="item.divergenciaPagamento < 0 ? 'color: red' : ''">
                  {{ item.divergenciaPagamento }}
                </td>
              </template>

              <template #item.divergenciaPeso="{ item }">
                <span :style="item.divergenciaPeso < 0 ? 'color: red' : ''">{{
                  item.divergenciaPeso
                }}</span>
              </template>

              <!-- Inicio badge -->
              <template #item.comparativo="{ item }">
                <div
                  @click="carregarDetalhes(item, 1)"
                  style="cursor: pointer; display: flex; align-items: center; justify-content: center;"
                >
                  <td>
                    <v-icon size="16" :color="getColor(item)"
                      >fa-solid fa-circle</v-icon
                    >
                  </td>
                </div>
              </template>
              <!-- Final badge -->

              <!-- Inicio badge -->
              <template #item.statusEmbarque="{ item }">
                <div
                  @click="carregarDetalhes(item, 1)"
                  style="cursor: pointer; display: flex; align-items: center; justify-content: center;"
                >
                  <td>
                    <v-icon size="16" :color="getColorEmbarque(item)"
                      >fa-solid fa-circle</v-icon
                    >
                  </td>
                </div>
              </template>
              <!-- Final badge -->

              <!-- Inicio badge -->
              <template #item.statusConemb="{ item }">
                <div
                  @click="carregarDetalhes(item, 1)"
                  style="cursor: pointer; display: flex; align-items: center; justify-content: center;"
                >
                  <td>
                    <v-icon size="16" :color="getColorConemb(item)"
                      >fa-solid fa-circle</v-icon
                    >
                  </td>
                </div>
              </template>
              <!-- Final badge -->
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Fim principal -->
  </div>
</template>

<script>
import { baseApiUrl, baseApiUrlPersys, showSuccess, showError } from "@/global";
import {VMoney} from 'v-money';
import axios from "axios";
import moment from "moment";
import Template from "../components/Template.vue";

export default {
  components: {
    Template,
    VMoney
  },
  name: "Objetos",
  data: () => ({
    altura: 'Altura',
    largura: 'Largura',
    comprimento: 'Comprimento',
    peso: 'Peso',

    modalRequisicaoCotacao: false,
    modalRequisicaoEmbalamento: false,



    codigo_empresa: '',
    numeroPedido: null,
    search: "",
    dataInicio: moment (new Date(new Date().getTime()).setDate(new Date().getDate() - 7)).format("YYYY-MM-DD"),
    dataFinal: moment(new Date()).format("YYYY-MM-DD"),

    grid: {
      produtos: {
        cabecalho: [
          { text: "Código", value: "codigo" },
          { text: "Quantidade", value: "quantidade", align: "right" },
          { text: "Altura", value: "altura", align: "right" },
          { text: "Largura", value: "largura", align: "right" },
          { text: "Comprimento", value: "comprimento", align: "right" },
          { text: "Peso", value: "peso", align: "right" },
          { text: "Preço", value: "valor", align: "right" },
        ],
        items: [],
      },
      cotacao: {
        cabecalho: [
          { text: "Código", value: "sku" },
          { text: "Quantidade", value: "quantity", align: "right" },
          { text: "Altura", value: "height", align: "right" },
          { text: "Largura", value: "width", align: "right" },
          { text: "Comprimento", value: "length", align: "right" },
          { text: "Peso", value: "weight", align: "right" },
          { text: "Preço", value: "price", align: "right" },
        ],
        items: [],
      },
      retornoCotacao: {
        cabecalho: [
          { text: "Base", value: "Base" },
          { text: "Custo", value: "custo", align: "right" },
          { text: "Peso", value: "peso", align: "right" },
          { text: "Prazo Transp", value: "prazo transporte", align: "right" },
          { text: "Aliq. acresc. token", value: "aliquota_acrescimo_token", align: "right" },
          { text: "Base acresc. token", value: "base_acrescimo_token", align: "right" },
          { text: "Valor acresc. token", value: "valor_acrescimo_token", align: "right" },
          { text: "Base difal", value: "base_difal", align: "right" },
          { text: "Valor difal", value: "valor_difal", align: "right" },
          { text: "Valor produto difal", value: "valor_produto_difal", align: "right" },
          { text: "Aliquota difal", value: "aliquota_difal", align: "right" },
          { text: "Tipo difal", value: "tipo_difal" },
          { text: "Base calc. adicional", value: "base_calc_adicional", align: "right" },
        ],
        items: [],
      },
      produtosEmbalamento: {
        cabecalho: [
          { text: "Quantidade", value: "quantidade" },
          { text: "Altura", value: "altura", align: "right" },
          { text: "Largura", value: "largura", align: "right" },
          { text: "Comprimento", value: "comprimento", align: "right" },
          { text: "Peso", value: "peso", align: "right" },
          { text: "Preço", value: "valor", align: "right" },
        ],
        cabecalhoUS: [
          { text: "Quantidade", value: "quantity" },
          { text: "Altura", value: "height", align: "right" },
          { text: "Largura", value: "width", align: "right" },
          { text: "Comprimento", value: "length", align: "right" },
          { text: "Peso", value: "weight", align: "right" },
          { text: "Preço", value: "price", align: "right" },
        ],
        items: [],
      },
      retornoEmbalamento: {
        cabecalho: [
          { text: "Base", value: "Base" },
          { text: "Custo", value: "custo", align: "right" },
          { text: "Valor frete", value: "valor_frete", align: "right" },
          { text: "Peso", value: "peso", align: "right" },
          { text: "Prazo transporte", value: "prazo_transporte", align: "right" }
        ],
        items: [],
      },
      objetos: {
        cabecalhoReverso: [
          { text: "Data Postagem", value: "dataPostagem" },
          {
            text: "Transportadora",
            value: "transportadoraNome",
          },
          { text: "Código Rastreio", value: "numeroRastreio"},
          { text: "Rastreio", value: "numeroRastreioStatus"},
          { text: "Prazo", value: "prazoEntrega"},
          { text: "Data Considerada", value: "dataConsiderada"},
          { text: "Data Prevista", value: "dataPrevista"},
          { text: "Data Entrega", value: "dataEntrega"},
          { text: "Cep Origem", value: "remetenteCep", align: "right" },
          { text: "Cep Destino", value: "destinatarioCep", align: "right" },
          { text: "Data Movimentação", value: "dataUltimaPosicao"},
          { text: "Detalhe Movimentação", value: "ultimaPosicao"},
        ],
        cabecalhoEmpresaCinco: [
          { text: "Pedido", value: "numeroPedido" },
          { text: "Data Pedido", value: "dataVenda"},
          { text: "Data Prevista", value: "dataPrevista"},
          { text: "Última Posição", value: "dataUltimaPosicao"},
          {
            text: "Transportadora",
            value: "transportadoraNome",
          },
          {
            text: "Serviço Entrega",
            value: "servicoEntrega",
          },
          {
            text: "Subcanal",
            value: "subCanal",
          },
          {
            text: "UF",
            value: "destinatarioEstado",
          },
          {
            text: "Entrega domicílio ",
            value: "entregaDomicilio",
          },
          {
            text: "Difal",
            value: "difal",
          },
          { text: "Status", value: "itemStatus" },
          { text: "Rastreio", value: "numeroRastreioStatus"},
          { text: "Embarque", value: "calcEmbarque", align: "end" },
          { text: "Auditoria", value: "calcAuditoria", align: "end" },
          { text: "Tabela", value: "calcTabela", align: "end" },
          { text: "Peso", value: "calcPeso", align: "end" },
        ],
        cabecalhoEmpresaCincoBckp: [
          { text: "Pedido", value: "numeroPedido" },
          { text: "Data Pedido", value: "dataVenda"},
          { text: "Data Prevista", value: "dataPrevista"},
          { text: "Última Posição", value: "dataUltimaPosicao"},
          {
            text: "Transportadora",
            value: "transportadoraNome",
          },
          {
            text: "Serviço Entrega",
            value: "servicoEntrega",
          },
          {
            text: "Subcanal",
            value: "subCanal",
          },
          {
            text: "UF",
            value: "destinatarioEstado",
          },
          {
            text: "Entrega domicílio ",
            value: "entregaDomicilio",
          },
          {
            text: "Difal",
            value: "difal",
          },
          { text: "Status", value: "itemStatus" },
          { text: "Rastreio", value: "numeroRastreioStatus" },
          { text: "Venda", value: "calcVenda"},
          { text: "Embarque", value: "calcEmbarque" },
          { text: "Auditoria", value: "calcAuditoria" },
          { text: "Tabela", value: "calcTabela" },
          { text: "Peso", value: "calcPeso" },
        ],
        cabecalho: [
          { text: "#", value: "codigo", sortable: false },
          { text: "Transp.", value: "transportadora", sortable: false },
          { text: "Rastreio", value: "numero_rastreio", sortable: false },
          { text: "Prazo", value: "prazo_entrega", sortable: false },
          {
            text: "Dt. Cons.",
            value: "data_considerada",
            sortable: false,
            formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null),
          },
          {
            text: "Dt. Prev",
            value: "data_prevista",
            sortable: false,
            formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null),
          },
          {
            text: "Dt. Ent.",
            value: "data_entrega",
            sortable: false,
            formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null),
          },
          { text: "Cep Origem", value: "remetente_cep", sortable: false },
          { text: "Cep Destino", value: "destinatario_cep", sortable: false },
          {
            text: "Dt. Mov.",
            value: "data_ultima_posicao",
            sortable: false,
            formatter: (x) =>
              x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null,
          },
          { text: "Mov.", value: "ultima_posicao", sortable: false },
          { text: "Rec", value: "reclamacao", sortable: false },
          { text: "!", value: "alerta", sortable: false },
        ],
        itemsReverso: [],
        items: [],
        carregando: false,
        paginaAtual: 1,
        totalRegistros: 0,
        qtdsPaginacao: [10, 15, 50, 100],
        porPagina: 10,
        itemSelecionado: [],
        coluna: "objetos.codigo",
        direcao: "ASC",
        direcaoConferencia: -1, // tem q ver pra colocar ele começando diferente de todos abaixo
        direcaoEmbarque: -1,
        direcaoConemb: -1,
        conferencia: [
          { text: "Todos", value: -1 },
          { text: "Ok", value: 0 },
          { text: "Pendente", value: 1 },
          { text: "Divergente", value: 2 },
        ],
        embarque: [
          { text: "Todos", value: -1 },
          { text: "Ok", value: 0 },
          { text: "Alerta", value: 1 },
        ],
        conemb: [
          { text: "Todos", value: -1 },
          { text: "Ok", value: 0 },
          { text: "Alerta", value: 1 },
        ],
        ordenacao: {
          colunas: [
            { text: "Código", value: "objetos.codigo" },
            { text: "Transportadora", value: "transportadoras.transportadora" },
            { text: "Rastreio", value: "objetos.numero_rastreio" },
            { text: "Prazo", value: "objetos.prazo_entrega" },
            { text: "Dt. Cons.", value: "objetos.data_considerada" },
            { text: "Dt. Prev", value: "objetos.data_prevista" },
            { text: "Dt. Ent.", value: "objetos.data_entrega" },
            { text: "Cep Origem", value: "objetos.remetente_cep" },
            { text: "Cep Destino", value: "objetos.destinatario_cep" },
            { text: "Dt. Mov.", value: "objetos.data_ultima_posicao" },
            { text: "Mov.", value: "objetos.ultima_posicao" },
          ],
          direcao: [
            { text: "Asc", value: "ASC" },
            { text: "Desc", value: "DESC" },
          ],
        },
        perPage: 50,
        actualPage: 1,
        totSize: 0,
        totalPage: 1,
      },
      detalhes: {
        cabecalho: [
          {
            text: "Data",
            value: "data_hora",
            sortable: false,
            formatter: (x) =>
              x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null,
            width: 150
          },
          { text: "Status", value: "descricao", sortable: false },
          { text: "Origem", value: "origem_cidade_uf_local", sortable: false },
          {
            text: "Destino",
            value: "destino_cidade_uf_local",
            sortable: false,
          },
        ],
        items: [],
        carregando: false,
      },
      manifesto: {
        cabecalho: [
          {
            text: "Data",
            value: "data",
            sortable: false,
            formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null),
          },
          { text: "Assunto", value: "assunto", sortable: false },
          { text: "Recebido", value: "texto", sortable: false },
          { text: "Enviado", value: "resposta", sortable: false },
          { text: "Prazo", value: "prazo", sortable: false },
          { text: "Pagamento", value: "pagamento", sortable: false },
          { text: "Status", value: "status", sortable: false },
          { text: "Ações", value: "controls", sortable: false },
        ],
        items: [],
        carregando: false,
      },
    },
    modal: {
      filtro: false,
      stopEmbalamento: false,
      filtroEmpresaCinco: false,
      dt_considerada: false,
      dt_prevista: false,
      dt_entrega: false,
      dt_ultima_posicao: false,
      rastreio: false,
      detalhes: false,
      loading: false,
      pi: false,
      sendComplaint: false,
      carregarConferencia: false,
      exportacao: false,
      data_inicio: false,
      data_final: false,
      detalhes_conferencia: false,
      data_inicio_pesquisa: false,
      data_final_pesquisa: false,
    },
    filtros: {
      estados: [
        'AC',
        'AL',
        'AP',
        'AM',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MT',
        'MS',
        'MG',
        'PA',
        'PB',
        'PR',
        'PE',
        'PI',
        'RJ',
        'RN',
        'RS',
        'RO',
        'RR',
        'SC',
        'SP',
        'SE',
        'TO',
      ],
      estado: null,
      subCanais: [],
      subCanal: null,
      codigo: null,
      cod_transportadora: null,
      numero_rastreio: null,
      prazo: null,
      dt_considerada: null,
      dt_prevista: null,
      dt_entrega: null,
      cep_origem: null,
      cep_destino: null,
      dt_ultima_posicao: null,
      ultima_posicao: null,
      data_inicio: null,
      data_final: null,
      difal: null,
      allDates: true,
    },
    filtrosEmpresaCinco: {
      numeroPedido: "",
      transportadoraNome: "",
      numeroRastreio: "",
      prazoEntrega: "",
      dataConsiderada: "",
      dataPrevista: "",
      dataEntrega: "",
      remetenteCep: "",
      destinatarioCep: "",
      dataUltimaPosicao: "",
      ultimaPosicao: "",
      difal: "Todos",
      subCanal: "Todos",
      uf: "Todos",
      opcoesDifal: ['SIM', 'NÃO'],
    },
    transportadoras: [],
    transportadorasCodigo: [],
    canais: ["Todos"],
    subcanais: [],
    ufs: [],
    rastreios: [],
    detalhes: [],
    objetoAtual: "",
    loading: false,
    loadingReprocessar: false,
    habilita: false,
    filtro: "",
    detalhes: {
      enviado: {
        data_postagem: "",
        data_considerada: "",
        cep: "",
        cidade: "",
      },
      para: {
        nome: "",
        cep: "",
        cidade: "",
        bairro: "",
        rua: "",
      },
      previsao_de_entrega: {
        data: "",
        em_domicilio: "",
      },
      entregue_em: {
        data: "",
      },
      cod_servico: '',
      ultima_posicao: '',
      dt_ultima_posicao: '',
      numero_rastreio: "",
      numero_danfe: "",
      order_id: "",
    },
    objeto: {
      numero_rastreio: "",
      rastreio: "",
      habilita: false,
      protocolo_reclamacao: "",
    },
    reclamacao: {
      codigo: "",
      numero_rastreio: "",
      cod_empresa: "",
    },
    itensReclamacao: [
      { cod: 132, text: "132 - Objeto entregue indevidamente" },
      { cod: 133, text: "133 - Correspondência Violada" },
      { cod: 134, text: "134 - Conteúdo Avariado" },
      { cod: 135, text: "135 - Objeto entregue com atraso" },
      { cod: 136, text: "136 - Objeto devolvido indevidamente" },
      { cod: 142, text: "142 - Remetente não recebeu o pedido de cópia" },
      { cod: 148, text: "148 - Remetente não recebeu o AR" },
      { cod: 211, text: "211 - Destinatário não recebeu a correspondência" },
      { cod: 226, text: "226 - Pagamento Não Realizado" },
      { cod: 240, text: "240 - AR Digital - Imagem não disponível" },
      { cod: 310, text: "310 - Entrega em Caixa de Correio Inteligente" },
    ],
    conferencia: {
      transportadoraNome: null,
      subCanal: null,
      destinatarioEstado: null,
      lucro_final: null,
      divergencia_tabela: null,
      lucro_embarque: null,
      diferenca_venda: null,
      diferenca_custo: null,
      diferenca_peso: null,
      valor_produtos: null,
      valor_frete: null,
      calculo_reverso: null,
      total_frete_conferencia: null,
      total_peso_conferencia: null,
      custo_frete: null,
      difal_frete: null,
      ganho_frete: null,
      taxa_frete: null,
      lucro_frete: null,
      embarque_peso: null,
      embarque_custo_frete: null,
      custo_frete_pago: null,
      custo_frete_tabela: null,
      numero_dacte: null,
      peso_pago: null,
      jsonProdutos: null,
      jsonSisFrete: null,
      jsonSisFreteRequisicao: null,
      jsonStatus: null,
      jsonSisFreteEmbalamento: null,
      jsonSisFreteEmbalamentoRequisicao: null,
      numero_pedido: null,
      base_transportadora: null,
    },
    stopEmbalamento: {
      numero_pedido: null,
      valor_produtos: null,
      valor_frete: null,
      base_transportadora: null,
      preco: null,
    },
    linhas: [{
      altura: 0,
      largura: 0,
      comprimento: 0,
      peso: 0,
      quantidade: 0,
    }],
    itemsExportacao: [],
    tabIndex: 0,
    tabs: [
      'Detalhes do objeto',
      'Conferência',
    ],
    tabsGrid: [
      {description: 'Reverso', value: 0},
      {description: 'Geral', value: 1},
      {description: 'Pedidos', value: 2},
      {description: 'Stop Embalamento', value: 3},
      {description: 'Embalamento', value: 4},
      {description: 'Faturamento', value: 5},
      {description: 'Conferência', value: 6},
      {description: 'Concluído', value: 7},
    ],
    backupItems: [],
    tabIndexGrid: 1,
    updateGrid: false,
    tokenApi: "",
    transpEmbalamento: [],
    dadosCotacao: {
      cepDestino: '',
      token: '',
    },
    dadosEmbalamento: {
      cepDestino: '',
      token: '',
    },
    dadosStatus: [],
    statusObjeto: '',
    ultimaPosicao: '',
    objetoDetalhes: {
      numero_rastreio: '',
      cep_de: '',
      cep_para: '',
      cod_servico: '',
    },
    json: {
      cotacao: [],
      embalamento: [],
    },
    opcDifal: [
      {
        codigo: 1,
        descricao: 'Sim',
      },
      {
        codigo: 2,
        descricao: 'Não',
      }
    ],
    pesquisando: false,
    dadosPedido: [],
    dadosTransportadoraBanco: [],
    modeloPaginacaoAtual: 0,
    lastTypeSearch: 0,
    dadosTransportadoraPersysAPI: [],
  }),
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
  },
  async mounted() {
    await this.checkEmpresa();
    this.changeCabecalho();
    this.tabItemsGrid(this.tabIndexGrid);
    this.carregarTokens();
    this.carregarSubCanais();
  },
  computed: {
    formatDateInicio() {
      return this.filtros.data_inicio
        ? moment(this.filtros.data_inicio).format("DD/MM/YYYY")
        : "";
    },
    formatDateFinal() {
      return this.filtros.data_final
        ? moment(this.filtros.data_final).format("DD/MM/YYYY")
        : "";
    },
    formatDateInicioGrid() {
      return this.dataInicio
        ? moment(this.dataInicio).format("DD/MM/YYYY")
        : "";
    },
    formatDateFinalGrid() {
      return this.dataFinal
        ? moment(this.dataFinal).format("DD/MM/YYYY")
        : "";
    },
    formatDateDataPrevista() {
      return this.filtrosEmpresaCinco.dataPrevista
        ? moment(this.filtrosEmpresaCinco.dataPrevista).format("DD/MM/YYYY")
        : "";
    },
    formatDateDataConsiderada() {
      return this.filtrosEmpresaCinco.dataConsiderada
        ? moment(this.filtrosEmpresaCinco.dataConsiderada).format("DD/MM/YYYY")
        : "";
    },

    formatDateDataEntrega() {
      return this.filtrosEmpresaCinco.dataEntrega
        ? moment(this.filtrosEmpresaCinco.dataEntrega).format("DD/MM/YYYY")
        : "";
    },
    formatDateDataUltPosicao() {
      return this.filtrosEmpresaCinco.dataUltimaPosicao
        ? moment(this.filtrosEmpresaCinco.dataUltimaPosicao).format("DD/MM/YYYY")
        : "";
    },
    formatDateDtPrevista() {
      return this.filtros.dt_prevista
        ? moment(this.filtros.dt_prevista).format("DD/MM/YYYY")
        : "";
    },
    formatDateDtConsiderada() {
      return this.filtros.dt_considerada
        ? moment(this.filtros.dt_considerada).format("DD/MM/YYYY")
        : "";
    },
    formatDateDtUltimaPosicao() {
      return this.filtros.dt_ultima_posicao
        ? moment(this.filtros.dt_ultima_posicao).format("DD/MM/YYYY")
        : "";
    },
    formatDateDtEntrega() {
      return this.filtros.dt_entrega
        ? moment(this.filtros.dt_entrega).format("DD/MM/YYYY")
        : "";
    }
  },
  methods: {

    copiarJsonCotacao(){
      // Get the text field
      var copyText = document.getElementById("jsonCotacao");
      // console.log(copyText)
      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.innerHTML);

    },
    copiarJsonEmbalamento(){
      // Get the text field
      var copyText = document.getElementById("jsonEmbalamento");
      // console.log(copyText)
      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.innerHTML);

    },

    async salvarEmbalamento(){
      this.linhas.forEach(element => {
        element.altura = Number(element.altura)
        element.largura = Number(element.largura)
        element.comprimento = Number(element.comprimento)
        element.quantidade = Number(element.quantidade)
        element.peso = Number(element.peso)
      });
      this.tokenApi = ""
      await this.getTokenApi()

      let valid = true;

      this.linhas.forEach(element => {
        if( element.altura == 0 || element.largura == 0 || element.comprimento == 0 || element.peso == 0 || element.quantidade == 0) {
          valid = false;
        }
      });

      if(valid) {
        await axios
        .post(`${baseApiUrlPersys}/embalamento`, {
          numeroDoPedido: this.numeroPedidoRequest,
          embalamentos: this.linhas,
          preco: Number(this.stopEmbalamento.preco),
        },
        {headers: {'Token-API': this.tokenApi}})
        .then((res) => {
          this.$swal({
            title: 'Sucesso!!!',
            text: 'Embalamento salvo com sucesso.',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
          this.modal.stopEmbalamento = false;
          this.tabItemsGrid(3);
        })
        .catch((error) => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
          this.dialogError = true;
        })
      }else{
        this.$swal({
          title: 'Erro!!!',
          text: 'Campos do embalamento não podem ser 0(zero).',
          icon: 'error',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#4BB543',
          allowOutsideClick: true,
        });
      }

    },

    async getTokenApi(){
      await axios
        .get(
          `${baseApiUrlPersys}/chaves/${this.codigo_empresa}`
        )
        .then((res) => {
          this.tokenApi = res.data.tokenApi
          // console.log(this.tokenApi)
        });
    },

    removeLinha(index){
      this.linhas.splice(index, 1);
    },

    addLinha(){
      this.linhas.push({altura:'0.00',largura:'0.00', comprimento: '0.00', peso:'0.000', quantidade:'0'})
    },

    limparLinhas(){
      this.linhas = [{
        altura: 0,
        largura: 0,
        comprimento: 0,
        peso: 0,
        quantidade: 0,
      }]
    },

    async carregarEmbalamento(item){
      this.limparLinhas()
      this.modal.stopEmbalamento = true,
      this.stopEmbalamento.numero_pedido = item.numeroPedido;
      this.stopEmbalamento.valor_produtos = Number(item.valorProdutos).toFixed(2);
      this.stopEmbalamento.valor_frete = Number(item.valorFrete).toFixed(2);
      this.stopEmbalamento.preco = Number(item.valorProdutos + item.valorFrete).toFixed(2);
      this.stopEmbalamento.base_transportadora = item.baseTransportadora;

      this.numeroPedidoRequest = item.pedidoCanalVenda

      await axios
        .get(
          `${baseApiUrlPersys}/order/${this.numeroPedidoRequest}`, {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          }
        )
        .then((res) => {
          this.grid.produtosEmbalamento.items = JSON.parse(res.data.jsonProdutos);

          this.grid.produtosEmbalamento.items.forEach(element => {
            element.altura = Number(element.altura).toFixed(2);
            element.largura = Number(element.largura).toFixed(2);
            element.comprimento = Number(element.comprimento).toFixed(2);
            element.peso = Number(element.peso).toFixed(2);
            element.preco = Number(element.preco).toFixed(2);
          });

          this.stopEmbalamento.quantidade = this.grid.produtosEmbalamento.items.length;
          this.grid.produtosEmbalamento.items.forEach(element => {
              this.stopEmbalamento.peso += element.peso;
              this.stopEmbalamento.altura += element.altura;
              this.stopEmbalamento.largura += element.largura;
              this.stopEmbalamento.comprimento += element.comprimento;
            });
          }).catch(() => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        });
    },

    async filtroModalObjetos(recarregarObjetos = true){
      if(recarregarObjetos){
        this.grid.objetos.items = ""
        await this.carregarObjetosEmpresaCinco();
        await this.carregarItemsReverso();
      }
      if (this.filtrosEmpresaCinco.numeroPedido != "" && this.filtrosEmpresaCinco.numeroPedido != null) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.numeroPedido == this.filtrosEmpresaCinco.numeroPedido
        );
      }
      if (this.filtrosEmpresaCinco.transportadoraNome != "" && this.filtrosEmpresaCinco.transportadoraNome != null) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.transportadoraNome == this.filtrosEmpresaCinco.transportadoraNome
        );
      }
      if (this.filtrosEmpresaCinco.numeroRastreio != "" && this.filtrosEmpresaCinco.numeroRastreio != null) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.numeroRastreio == this.filtrosEmpresaCinco.numeroRastreio
        );
      }
      if (this.filtrosEmpresaCinco.prazoEntrega != "" && this.filtrosEmpresaCinco.prazoEntrega != null) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.prazoEntrega == this.filtrosEmpresaCinco.prazoEntrega
        );
      }
      if (this.filtrosEmpresaCinco.dataConsiderada != "") {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.dataConsiderada == this.filtrosEmpresaCinco.dataConsiderada
        );
        // this.grid.objetos.items = this.grid.objetos.items.filter(
        //   (obj) => obj.dataConsiderada == moment(this.filtrosEmpresaCinco.dataConsiderada).format("DD/MM/YYYY")
        // );
      }
      if (this.filtrosEmpresaCinco.dataPrevista != "") {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.dataPrevista == moment(this.filtrosEmpresaCinco.dataPrevista).format("DD/MM/YYYY")
        );
      }
      if (this.filtrosEmpresaCinco.dataEntrega != "") {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.dataEntrega == this.filtrosEmpresaCinco.dataEntrega
        );
      }
      if (this.filtrosEmpresaCinco.remetenteCep != "") {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.remetenteCep == this.filtrosEmpresaCinco.remetenteCep
        );
      }
      if (this.filtrosEmpresaCinco.destinatarioCep != "") {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.destinatarioCep == this.filtrosEmpresaCinco.destinatarioCep
        );
      }
      if (this.filtrosEmpresaCinco.dataUltimaPosicao != "" && this.filtrosEmpresaCinco.dataUltimaPosicao != null) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.dataUltimaPosicao == moment(this.filtrosEmpresaCinco.dataUltimaPosicao).format("DD/MM/YYYY")
        );
      }
      if (this.filtrosEmpresaCinco.ultimaPosicao != "" && this.filtrosEmpresaCinco.ultimaPosicao != null) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.ultimaPosicao == this.filtrosEmpresaCinco.ultimaPosicao
        );
      }
      if (this.filtrosEmpresaCinco.subCanal != "" && this.filtrosEmpresaCinco.subCanal != null && this.filtrosEmpresaCinco.subCanal != "Todos") {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.subCanal == this.filtrosEmpresaCinco.subCanal
        );
      }
      if (this.filtrosEmpresaCinco.uf != "" && this.filtrosEmpresaCinco.uf != null && this.filtrosEmpresaCinco.uf != "Todos") {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.destinatarioEstado == this.filtrosEmpresaCinco.uf
        );
      }
      if (this.filtrosEmpresaCinco.difal != "" && this.filtrosEmpresaCinco.difal != null && this.filtrosEmpresaCinco.difal != 'Todos') {
        if(this.filtrosEmpresaCinco.difal == 1){
          this.grid.objetos.items = this.grid.objetos.items.filter(
            (obj) => obj.detBaseDifal != null
          );
        }
        if(this.filtrosEmpresaCinco.difal == 2){
          this.grid.objetos.items = this.grid.objetos.items.filter(
            (obj) => obj.detBaseDifal == null || obj.detBaseDifal == 0
          );
        }
      }

      for (let i = 0; i < this.grid.objetos.items.length; i++) {
        if(this.filtrosEmpresaCinco.subCanal == "" || this.filtrosEmpresaCinco.subCanal == null || this.filtrosEmpresaCinco.subCanal == "Todos") {
          if(i == 0)
            this.subcanais = [];
          if(this.grid.objetos.items[i].subCanal != null) {
            if (!this.subcanais.includes(this.grid.objetos.items[i].subCanal)) {
              if(this.subcanais.length == 0) {
                this.subcanais.push('Todos');
              }
              this.subcanais.push(this.grid.objetos.items[i].subCanal);
            }
          }
        }

        if(this.filtrosEmpresaCinco.uf == "" || this.filtrosEmpresaCinco.uf == null || this.filtrosEmpresaCinco.uf == "Todos") {
          if(i == 0)
            this.ufs = [];
          if (!this.ufs.includes(this.grid.objetos.items[i].destinatarioEstado)) {
            if(this.ufs.length == 0) {
              this.ufs.push('Todos');
            }
            this.ufs.push(this.grid.objetos.items[i].destinatarioEstado);
          }
        }
      }
    },

    limparFiltrosEmpresaCinco(){
      this.filtrosEmpresaCinco.numeroPedido = ""
      this.filtrosEmpresaCinco.transportadoraNome = ""
      this.filtrosEmpresaCinco.numeroRastreio = ""
      this.filtrosEmpresaCinco.prazoEntrega = ""
      this.filtrosEmpresaCinco.dataConsiderada = ""
      this.filtrosEmpresaCinco.dataPrevista = ""
      this.filtrosEmpresaCinco.dataEntrega = ""
      this.filtrosEmpresaCinco.remetenteCep = ""
      this.filtrosEmpresaCinco.destinatarioCep = ""
      this.filtrosEmpresaCinco.dataUltimaPosicao = ""
      this.filtrosEmpresaCinco.ultimaPosicao = ""
      this.filtrosEmpresaCinco.subCanal = "Todos"
      this.filtrosEmpresaCinco.uf = "Todos"
      this.filtrosEmpresaCinco.difal = "Todos"
    },

    tabItemsGrid(tab){
      this.tabIndexGrid = tab;


      if(this.tabIndexGrid == 0){ // REVERSO
        this.grid.objetos.cabecalhoEmpresaCinco = this.grid.objetos.cabecalhoReverso;
      }else if(this.tabIndexGrid == 1){ // GERAL
        this.grid.objetos.cabecalhoEmpresaCinco = this.grid.objetos.cabecalhoEmpresaCincoBckp;
      } else if (this.tabIndexGrid == 2) { // PEDIDOS
        this.grid.objetos.cabecalhoEmpresaCinco = this.grid.objetos.cabecalhoEmpresaCincoBckp.filter((e) => e.value != 'itemStatus');
      }else if (this.tabIndexGrid == 3) { // STOP-EMBALAMENTO
        this.grid.objetos.cabecalhoEmpresaCinco = this.grid.objetos.cabecalhoEmpresaCincoBckp.filter((e) => e.value != 'itemStatus' && e.value != 'calcEmbarque' && e.value != 'calcAuditoria' && e.value != 'calcTabela' && e.value != 'calcPeso');
      }else if (this.tabIndexGrid == 4) { // EMBALAMENTO
        this.grid.objetos.cabecalhoEmpresaCinco = this.grid.objetos.cabecalhoEmpresaCincoBckp.filter((e) => e.value != 'itemStatus' && e.value != 'calcEmbarque' && e.value != 'calcAuditoria' && e.value != 'calcTabela' && e.value != 'calcPeso');
      }else if (this.tabIndexGrid == 5) { // FATURAMENTO
        this.grid.objetos.cabecalhoEmpresaCinco = this.grid.objetos.cabecalhoEmpresaCincoBckp.filter((e) => e.value != 'itemStatus' && e.value != 'calcAuditoria' && e.value != 'calcTabela' && e.value != 'calcPeso');
      }else if (this.tabIndexGrid == 6) { // CONFERENCIA
        this.grid.objetos.cabecalhoEmpresaCinco = this.grid.objetos.cabecalhoEmpresaCincoBckp.filter((e) => e.value != 'itemStatus' && e.value != 'calcAuditoria' && e.value != 'calcTabela' && e.value != 'calcPeso');
      }else if (this.tabIndexGrid == 7) { // CONCLUIDO
        this.grid.objetos.cabecalhoEmpresaCinco = this.grid.objetos.cabecalhoEmpresaCincoBckp.filter((e) => e.value != 'itemStatus');
      }

      this.grid.objetos.items = this.backupItems;

      switch (tab?.toString()) {

        case '0': // REVERSO
          this.grid.objetos.items = this.grid.objetos.itemsReverso;
          break;

        case '1': // GERAL
          this.grid.objetos.items = this.grid.objetos.items;
          break;

        case '2': // PEDIDOS
          this.grid.objetos.items = this.grid.objetos.items.filter((e) => e.comparativo == null);
          break;

        case '3': // STOP-EMBALAMENTO
          this.grid.objetos.items = this.grid.objetos.items.filter((e) => e.stopEmbalamento == true && e.embarquePeso == null &&  e.embarqueCustoFrete == null);
          break;

        case '4': // EMBALAMENTO
          this.grid.objetos.items = this.grid.objetos.items.filter((e) => e.stopEmbalamento == null && e.embarquePeso == null &&  e.embarqueCustoFrete == null);
          // console.log(this.grid.objetos.items.length);
          break;

        case '5': // FATURAMENTO
          this.grid.objetos.items = this.grid.objetos.items.filter((e) => e.numeroRastreio == null);
          break;

        case '6': // CONFERENCIA
          this.grid.objetos.items = this.grid.objetos.items.filter((e) => (e.custoFretePago == null &&  e.pesoPago == null) || e.custoFreteTabela == null);
          break;

        case '7': // CONCLUIDO
          this.grid.objetos.items = this.grid.objetos.items.filter((e) => e.comparativo != null && e.embarquePeso != null &&  e.embarqueCustoFrete != null && e.numeroRastreio != null && e.custoFretePago != null &&  e.pesoPago != null && e.custoFreteTabela != null);
          break;
      }

      this.grid.objetos.actualPage = 1;
      if(this.lastTypeSearch == 0)
        this.carregarObjetosEmpresaCinco();
      else
        this.buscarObjetos();
      this.carregarItemsReverso();
      // this.filtrar(false);
      // this.filtroModalObjetos(false);
      this.updateGrid = !this.updateGrid;
    },

    async reprocessar() {
      this.loadingReprocessar = true;
      await axios
        .get(
          `${baseApiUrlPersys}/order/calcular_frete/force/numero_pedido/${this.numero_pedido}`, {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          }
        )
        .then(async (res) => {
          // console.log(res);
          if (res.status == 200) {
            await this.carregarObjetosEmpresaCinco();
            const itemModal = this.grid.objetos.items.find(
              (e) => e.pedidoCanalVenda == this.numero_pedido
            );
            // console.log(itemModal)
            if (itemModal) {
              this.carregarConferencia(itemModal);
            } else {
              await axios
              .get(
                `${baseApiUrlPersys}/order/${this.numero_pedido}`, {
                  headers: {
                    'Token-API': this.$store.state.token_persys
                  }
                }
              )
              .then((res) => {
                if(res.data != "") {
                  this.grid.produtos.items = JSON.parse(res.data.jsonProdutos);
                  // console.log(this.grid.produtos.items)
                  this.grid.produtos.items.forEach(element => {
                    element.altura = Number(element.altura * 100).toFixed(2);
                    element.largura = Number(element.largura * 100).toFixed(2);
                    element.comprimento = Number(element.comprimento * 100).toFixed(2);
                    element.peso = Number(element.peso * 1000).toFixed(2);
                  });

                this.dadosCotacao.token = JSON.parse(res.data.jsonSisfreteRequisicao).type.proced.code;
                this.dadosCotacao.cepDestino = JSON.parse(res.data.jsonSisfreteRequisicao).type.proced.search.destination
                this.grid.cotacao.items = JSON.parse(res.data.jsonSisfreteRequisicao).type.proced.search.items;
                this.grid.retornoCotacao.items = JSON.parse(res.data.jsonSisfrete).data[0];
                if(JSON.parse(res.data.jsonSisfreteEmbalamentoRequisicao) != null) {
                  this.dadosEmbalamento.token = JSON.parse(res.data.jsonSisfreteEmbalamentoRequisicao).type.proced.code;
                  this.dadosEmbalamento.cepDestino = JSON.parse(res.data.jsonSisfreteEmbalamentoRequisicao).type.proced.search.destination;
                  this.grid.produtosEmbalamento.items = JSON.parse(res.data.jsonSisfreteEmbalamentoRequisicao).type.proced.search.items;
                } else {
                  this.grid.produtosEmbalamento.items = [];
                }

                this.json.cotacao = JSON.parse(res.data.jsonSisfreteRequisicao);
                this.json.embalamento = JSON.parse(res.data.jsonSisfreteEmbalamentoRequisicao);

                this.grid.produtosEmbalamento.items.forEach(element => {
                  element.price = (element.price).toFixed(2);
                  element.height = (element.height).toFixed(2);
                  element.width = (element.width).toFixed(2);
                  element.length = (element.length).toFixed(2);
                  element.weight = Number(element.weight).toFixed(2);
                });

                this.grid.cotacao.items.forEach(element => {
                  element.height = (element.height).toFixed(2);
                  element.width = (element.width).toFixed(2);
                  element.length = (element.length).toFixed(2);
                  element.weight = Number(element.weight).toFixed(2);
                });

                if(JSON.parse(res.data.jsonSisfreteEmbalamento) != null) {
                  this.grid.retornoEmbalamento.items = JSON.parse(res.data.jsonSisfreteEmbalamento).data;
                } else {
                  this.grid.retornoEmbalamento.items = [];
                }

                // console.log(this.grid.retornoEmbalamento.items)

                // this.dadosStatus = JSON.parse(res.data.jsonStatus);
                this.dadosPedido = res.data;
                if(this.dadosPedido.dataEmissaoNota != "" && this.dadosPedido.dataEmissaoNota != null) {
                  this.dadosPedido.dataEmissaoNota = moment(this.dadosPedido.dataEmissaoNota).format("DD/MM/YYYY");
                }
                this.conferencia.calculo_reverso = res.data.reverso ? "Sim" : "Não";

                // this.grid.produtos.items.forEach(element => {
                //   element.weight = (element.weight / 1000 ).toFixed(3)
                // });

                this.conferencia.jsonProdutos = res.data.jsonProdutos;
                this.conferencia.jsonSisFrete = res.data.jsonSisfrete;
                this.conferencia.jsonSisFreteRequisicao =
                  res.data.jsonSisfreteRequisicao;
                // this.conferencia.jsonStatus = res.data.jsonStatus;
                this.conferencia.jsonSisFreteEmbalamento =
                  res.data.jsonSisfreteEmbalamento;
                this.conferencia.jsonSisFreteEmbalamentoRequisicao =
                  res.data.jsonSisfreteEmbalamentoRequisicao;
                this.conferencia.statusGeral = res.data.statusGeral;
                this.conferencia.mensagem =
                  res.data.mensagem == null ? "" : res.data.mensagem;
                }
              })
              .catch(() => {
                this.$swal({
                  title: 'Alerta',
                  text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
                  icon: 'warning',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#4BB543',
                  allowOutsideClick: true,
                });
              });
            }
          }
        })
        .catch(() => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        })
        .finally(() => {
          this.loadingReprocessar = false;
        });
    },
    async filtrarPorConferencia() {
      await this.carregarObjetosEmpresaCinco();
      if (this.grid.objetos.direcaoConferencia == 0) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.comparativo == 0
        );
      } else if (this.grid.objetos.direcaoConferencia == 1) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.comparativo == null
        );
      } else if (this.grid.objetos.direcaoConferencia == 2) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.comparativo != null && obj.comparativo != 0
        );
      }

      if (this.grid.objetos.direcaoEmbarque == 0) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => this.getColorEmbarque(obj) == "#3CA40B"
        );
      } else if (this.grid.objetos.direcaoEmbarque == 1) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => this.getColorEmbarque(obj) == "#FAB913"
        );
      }

      if (this.grid.objetos.direcaoConemb == 0) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => this.getColorConemb(obj) == "#3CA40B"
        );
      } else if (this.grid.objetos.direcaoConemb == 1) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => this.getColorConemb(obj) == "#FAB913"
        );
      }
    },

    async filtrarPorEmbarque() {
      await this.carregarObjetosEmpresaCinco();
      if (this.grid.objetos.direcaoEmbarque == 0) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => this.getColorEmbarque(obj) == "#3CA40B"
        );
      } else if (this.grid.objetos.direcaoEmbarque == 1) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => this.getColorEmbarque(obj) == "#FAB913"
        );
      }

      if (this.grid.objetos.direcaoConferencia == 0) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.comparativo == 0
        );
      } else if (this.grid.objetos.direcaoConferencia == 1) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.comparativo == null
        );
      } else if (this.grid.objetos.direcaoConferencia == 2) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.comparativo != null && obj.comparativo != 0
        );
      }

      if (this.grid.objetos.direcaoConemb == 0) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => this.getColorConemb(obj) == "#3CA40B"
        );
      } else if (this.grid.objetos.direcaoConemb == 1) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => this.getColorConemb(obj) == "#FAB913"
        );
      }
    },

    async filtrarPorConemb() {
      await this.carregarObjetosEmpresaCinco();
      if (this.grid.objetos.direcaoConemb == 0) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => this.getColorConemb(obj) == "#3CA40B"
        );
      } else if (this.grid.objetos.direcaoConemb == 1) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => this.getColorConemb(obj) == "#FAB913"
        );
      }

      if (this.grid.objetos.direcaoConferencia == 0) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.comparativo == 0
        );
      } else if (this.grid.objetos.direcaoConferencia == 1) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.comparativo == null
        );
      } else if (this.grid.objetos.direcaoConferencia == 2) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.comparativo != null && obj.comparativo != 0
        );
      }

      if (this.grid.objetos.direcaoEmbarque == 0) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => this.getColorEmbarque(obj) == "#3CA40B"
        );
      } else if (this.grid.objetos.direcaoEmbarque == 1) {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => this.getColorEmbarque(obj) == "#FAB913"
        );
      }
    },

    async filtrar(recarregarObjetos = true) {
      if (this.filtros.subcanal != "Todos") {
        this.grid.objetos.items = this.grid.objetos.items.filter(
          (obj) => obj.subCanal == this.filtros.subcanal
        );
      }
    },

    async exportarDados() {
      this.itemsExportacao = [];
      this.loading = true;
      await axios
        .get(
          `${baseApiUrlPersys}/order/objeto/interval?fim=${this.filtros.data_final}&inicio=${this.filtros.data_inicio}`, {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          }
        )
        .then((res) => {
          this.itemsExportacao = res.data;
          const data = this.itemsExportacao;
          const fileName = `${"Relatorio-referente-as-datas: "} ${this.formatDateInicio}-${this.formatDateFinal}`;
          console.log("this.itemsExportacao", this.itemsExportacao);
          var old = JSON.stringify(this.itemsExportacao); //.replace(/null/g, '""');
          var newArray = JSON.parse(old);
          var opts = {
            headers:true,
            columns: [
              {
                columnid:'numeroPedido',
                cell: {
                  style: ''
                }
              },
              {
                columnid:'pedidoCanalVenda',
                cell: {
                  style: ''
                }
              },
              {
                columnid:'dataVenda',
                cell: {
                  style: ''
                }
              },
              {
                columnid:'dataPostagem',
                cell: {
                  style: ''
                }
              },
              {
                columnid:'transportadoraNome',
                cell: {
                  style: ''
                }
              },
              {
                columnid:'servicoEntrega',
                cell: {
                  style: ''
                }
              },
              {
                columnid:'baseTransportadora',
                cell: {
                  style: ''
                }
              },
              {
                columnid:'canalVenda',
                cell: {
                  style: ''
                }
              },
              {
                columnid:'subCanal',
                cell: {
                  style: ''
                }
              },
              {
                columnid:'destinatarioNome',
                cell: {
                  style: ''
                }
              },
              {
                columnid:'destinatarioCidade',
                cell: {
                  style: ''
                }
              },
              {
                columnid:'destinatarioEstado',
                cell: {
                  style: ''
                }
              },
              {
                columnid:'valorProdutos',
                cell: {
                  style: 'text-align: right;',
                  value: function(value) {
                    let val = (value / 1).toFixed(2).replace(".", ",");
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                  }
                }
              },
              {
                columnid:'valorFrete',
                cell: {
                  style: 'text-align: right;',
                  value: function(value) {
                    let val = (value / 1).toFixed(2).replace(".", ",");
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                  }
                }
              },
              {
                columnid:'totalPedido',
                cell: {
                  style: 'text-align: right;',
                  value: function(value) {
                    let val = (value / 1).toFixed(2).replace(".", ",");
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                  }
                }
              },
              {
                columnid:'detDifalFrete',
                cell: {
                  style: 'text-align: right;',
                  value: function(value) {
                    let val = (value / 1).toFixed(2).replace(".", ",");
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                  }
                }
              },
              {
                columnid:'detBaseDifal',
                cell: {
                  style: 'text-align: right;',
                  value: function(value) {
                    let val = (value / 1).toFixed(2).replace(".", ",");
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                  }
                }
              },
              {
                columnid:'detTaxaFrete',
                cell: {
                  style: 'text-align: right;',
                  value: function(value) {
                    let val = (value / 1).toFixed(2).replace(".", ",");
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                  }
                }
              },
              {
                columnid:'detGanhoFrete',
                cell: {
                  style: 'text-align: right;',
                  value: function(value) {
                    let val = (value / 1).toFixed(2).replace(".", ",");
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                  }
                }
              },
              {
                columnid:'porcentagemGanho',
                cell: {
                  style: 'text-align: right;',
                  value: function(value) {
                    let val = (value / 1).toFixed(2).replace(".", ",");
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                  }
                }
              },
              {
                columnid:'detValorFrete',
                cell: {
                  style: 'text-align: right;',
                  value: function(value) {
                    let val = (value / 1).toFixed(2).replace(".", ",");
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                  }
                }
              },
              {
                columnid:'peso',
                cell: {
                  style: 'text-align: right;',
                  value: function(value) {
                    value = value == null || value == "" ? 0 : value;
                    value = value.toLocaleString("pt-br", { minimumFractionDigits: 3 });
                    return value;
                  }
                }
              },
              {
                columnid:'embarqueCustoFrete',
                cell: {
                  style: 'text-align: right;',
                  value: function(value) {
                    let val = (value / 1).toFixed(2).replace(".", ",");
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                  }
                }
              },
              {
                columnid:'embarquePeso',
                cell: {
                  style: 'text-align: right;',
                  value: function(value) {
                    value = value == null || value == "" ? 0 : value;
                    value = value.toLocaleString("pt-br", { minimumFractionDigits: 3 });
                    return value;
                  }
                }
              },
              {
                columnid:'lucroAposEmbarque',
                cell: {
                  style: 'text-align: right;',
                  value: function(value) {
                    let val = (value / 1).toFixed(2).replace(".", ",");
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                  }
                }
              },
              {
                columnid:'custoFretePago',
                cell: {
                  style: 'text-align: right;',
                  value: function(value) {
                    let val = (value / 1).toFixed(2).replace(".", ",");
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                  }
                }
              },
              {
                columnid:'pesoPago',
                cell: {
                  style: 'text-align: right;',
                  value: function(value) {
                    value = value == null || value == "" ? 0 : value;
                    value = value.toLocaleString("pt-br", { minimumFractionDigits: 3 });
                    return value;
                  }
                }
              },
              {
                columnid:'diferencaCobranca',
                cell: {
                  style: 'text-align: right;',
                  value: function(value) {
                    let val = (value / 1).toFixed(2).replace(".", ",");
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                  }
                }
              },
              {
                columnid:'diferencaPeso',
                cell: {
                  style: 'text-align: right;',
                  value: function(value) {
                    value = value == null || value == "" ? 0 : value;
                    value = value.toLocaleString("pt-br", { minimumFractionDigits: 3 });
                    return value;
                  }
                }
              },
              {
                columnid:'custoFreteTabela',
                cell: {
                  style: 'text-align: right;',
                  value: function(value) {
                    let val = (value / 1).toFixed(2).replace(".", ",");
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                  }
                }
              },
              {
                columnid:'diferenca',
                cell: {
                  style: 'text-align: right;',
                  value: function(value) {
                    let val = (value / 1).toFixed(2).replace(".", ",");
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                  },
                }
              },
              {
                columnid:'ganhoFinal',
                cell: {
                  style: 'text-align: right;',
                  value: function(value) {
                    let val = (value / 1).toFixed(2).replace(".", ",");
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                  }
                }
              },
              {
                columnid:'porcentagemFinal',
                cell: {
                  style: 'text-align: right;',
                  value: function(value) {
                    let val = (value / 1).toFixed(2).replace(".", ",");
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                  }
                }
              },
            ]
          };
          alasql('SELECT * INTO XLS("' + fileName + '.xls' + '", ?) FROM ?', [opts, newArray]);
        })
        .catch((error) => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
console.log("error", error);
          this.loading = false;
        })
        .finally(() => {
          this.filtros.data_inicio = null;
          this.filtros.data_final = null;
          this.loading = false;
        });
    },
    async carregarConferencia(item) {
      if (item.embarqueCustoFrete == null ){
        this.conferencia.lucro_embarque = null
      }else {
        this.conferencia.lucro_embarque = Number((item.detValorFrete - item.embarqueCustoFrete) + (item.detGanhoFrete - item.detTaxaFrete)).toFixed(2);
      }

      if (item.custoFretePago == null){
        this.conferencia.lucro_final = null
      }else {
        this.conferencia.lucro_final = Number((item.detValorFrete - item.custoFretePago) + (item.detGanhoFrete - item.detTaxaFrete)).toFixed(2);
      }

      if(item.custoFreteTabela == null){
        this.conferencia.divergencia_tabela = null
      }else {
        this.conferencia.divergencia_tabela = Number(item.custoFretePago - item.custoFreteTabela).toFixed(2);
      }

      this.conferencia.base_transportadora = item.baseTransportadora;
      this.conferencia.embarque_peso = Number(item.embarquePeso).toFixed(3);
      this.conferencia.total_peso_conferencia = Number(item.detPesoFrete).toFixed(3);
      this.conferencia.embarque_custo_frete = item.embarqueCustoFrete;
      this.conferencia.custo_frete_pago = Number(item.custoFretePago).toFixed(2);
      this.conferencia.custo_frete_tabela = item.custoFreteTabela;
      this.conferencia.numero_dacte = item.numeroDacte;
      this.conferencia.peso_pago = Number(item.pesoPago).toFixed(3);
      this.numeroPedido = item.numeroPedido;
      if (item.valorFrete !== null && item.detTotalFrete !== null) {
        this.conferencia.diferenca_venda = Number(item.valorFrete - item.detTotalFrete).toFixed(2);
      }
      if (item.embarqueCustoFrete !== null && item.custoFretePago !== null) {
        this.conferencia.diferenca_custo = Number(
          item.embarqueCustoFrete - item.custoFretePago
        ).toFixed(2);
      }
      if (item.embarquePeso !== null && item.pesoPago !== null) {
        this.conferencia.diferenca_peso = Number(
         item.pesoPago - item.embarquePeso
        ).toFixed(3);
      }
      this.conferencia.transportadoraNome = item.transportadoraNome;
      this.conferencia.subCanal = item.subCanal;
      this.conferencia.destinatarioEstado = item.destinatarioEstado;
      this.conferencia.valor_produtos = Number(item.valorProdutos).toFixed(2);
      this.conferencia.valor_frete = Number(item.valorFrete).toFixed(2);
      this.conferencia.total_frete_conferencia = item.detTotalFrete;
      this.conferencia.custo_frete = item.detValorFrete;
      this.conferencia.difal_frete = Number(item.detDifalFrete).toFixed(2);
      this.conferencia.ganho_frete = Number(item.detGanhoFrete).toFixed(2);
      this.conferencia.taxa_frete = item.detTaxaFrete;
      this.conferencia.lucro_frete = Number(
        this.conferencia.ganho_frete - this.conferencia.taxa_frete
      ).toFixed(2);

      this.numero_pedido = item.pedidoCanalVenda;

      await axios
        .get(
          `${baseApiUrlPersys}/order/${this.numero_pedido}`, {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          }
        )
        .then((res) => {
          if(res.data != "") {
            this.grid.produtos.items = JSON.parse(res.data.jsonProdutos);
            this.grid.produtos.items.forEach(element => {
              element.altura = Number(element.altura * 100).toFixed(2);
              element.largura = Number(element.largura * 100).toFixed(2);
              element.comprimento = Number(element.comprimento * 100).toFixed(2);
              element.peso = Number(element.peso * 1000).toFixed(2);
            });

          this.dadosCotacao.token = JSON.parse(res.data.jsonSisfreteRequisicao).type.proced.code;
          this.dadosCotacao.cepDestino = JSON.parse(res.data.jsonSisfreteRequisicao).type.proced.search.destination
          this.grid.cotacao.items = JSON.parse(res.data.jsonSisfreteRequisicao).type.proced.search.items;
          this.grid.retornoCotacao.items = JSON.parse(res.data.jsonSisfrete).data[0];
          if(JSON.parse(res.data.jsonSisfreteEmbalamentoRequisicao) != null) {
            this.dadosEmbalamento.token = JSON.parse(res.data.jsonSisfreteEmbalamentoRequisicao).type.proced.code;
            this.dadosEmbalamento.cepDestino = JSON.parse(res.data.jsonSisfreteEmbalamentoRequisicao).type.proced.search.destination;
            this.grid.produtosEmbalamento.items = JSON.parse(res.data.jsonSisfreteEmbalamentoRequisicao).type.proced.search.items;
          } else {
            this.grid.produtosEmbalamento.items = [];
          }

          this.json.cotacao = JSON.parse(res.data.jsonSisfreteRequisicao);
          this.json.embalamento = JSON.parse(res.data.jsonSisfreteEmbalamentoRequisicao);

          this.grid.produtosEmbalamento.items.forEach(element => {
            element.price = (element.price).toFixed(2);
            element.height = (element.height).toFixed(2);
            element.width = (element.width).toFixed(2);
            element.length = (element.length).toFixed(2);
            element.weight = Number(element.weight).toFixed(2);
          });

          this.grid.cotacao.items.forEach(element => {
            element.height = (element.height).toFixed(2);
            element.width = (element.width).toFixed(2);
            element.length = (element.length).toFixed(2);
            element.weight = Number(element.weight).toFixed(2);
          });

          if(JSON.parse(res.data.jsonSisfreteEmbalamento) != null) {
            this.grid.retornoEmbalamento.items = JSON.parse(res.data.jsonSisfreteEmbalamento).data;
          } else {
            this.grid.retornoEmbalamento.items = [];
          }

          this.dadosPedido = res.data;
          if(this.dadosPedido.dataEmissaoNota != "" && this.dadosPedido.dataEmissaoNota != null) {
            this.dadosPedido.dataEmissaoNota = moment(this.dadosPedido.dataEmissaoNota).format("DD/MM/YYYY");
          }
          this.conferencia.calculo_reverso = res.data.reverso ? "Sim" : "Não";

          this.conferencia.jsonProdutos = res.data.jsonProdutos;
          this.conferencia.jsonSisFrete = res.data.jsonSisfrete;
          this.conferencia.jsonSisFreteRequisicao =
            res.data.jsonSisfreteRequisicao;
          this.conferencia.jsonSisFreteEmbalamento =
            res.data.jsonSisfreteEmbalamento;
          this.conferencia.jsonSisFreteEmbalamentoRequisicao =
            res.data.jsonSisfreteEmbalamentoRequisicao;
          this.conferencia.statusGeral = res.data.statusGeral;
          this.conferencia.mensagem =
            res.data.mensagem == null ? "" : res.data.mensagem;
          }
        })
        .catch(() => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        });
    },


    getColorRastreio(item) {
      if(item.numeroRastreioStatus == "Sem rastreio"){
        return "color: #808080"
      } else if (item.numeroRastreioStatus == "Em transito"){
        return "color: #3CA40B"
      } else if (item.numeroRastreioStatus == "Em atraso"){
        return "color: #FF0000"
      } else if (item.numeroRastreioStatus == "Alerta"){
        return "color: #CED202"
      } else if (item.numeroRastreioStatus == "Entregue"){
        return "color: #4C4C4C"
      }else if (item.numeroRastreioStatus == "Não postado"){
        return "color: #414141"
      }else if (item.numeroRastreioStatus == "Erro"){
        return "color: #414141"
      }
    },

    getColorPeso(item) {
      if(item.calcPeso > 0 ){
        return "color: #3CA40B"
      }else if(item.calcPeso < 0){
        return "color: #FF0000"
      }else if(item.calcPeso == 0){
        return "color: #808080"
      }
    },

    getColorVenda(item){
      if(item.calcVenda.length <= 1){
        return "display: none"
      }else if(item.calcVenda > 20){
        return "color: #3CA40B"
      }else if(item.calcVenda >= 0 && item.calcVenda <= 20){
        return "color: #CED202"
      }else if(item.calcVenda < 0){
        return "color: #FF0000"
      }
    },

    getColorTabela(item){
      if(item.calcTabela.length <= 1){
        return "display: none"
      }else if(item.calcTabela > 0){
        return "color: #3CA40B"
      }else if(item.calcTabela < 0){
        return "color: #FF0000"
      }else if(item.calcTabela == 0){
        return "color: #808080"
      }
    },

    getColorAuditoria(item){
      if(item.calcAuditoria.length <= 1){
        return "display: none"
      }else if(item.calcAuditoria > 20 ){
        return "color: #3CA40B"
      }else if(item.calcAuditoria >= 0 && item.calcAuditoria <= 20){
        return "color: #CED202"
      }else if(item.calcAuditoria < 0){
        return "color: #FF0000"
      }
    },

    getColorEmbarqueGrid(item){
      if(item.calcEmbarque.length <= 1){
        return "display: none"
      }else if(item.calcEmbarque > 20){
        return "color: #3CA40B"
      }else if(item.calcEmbarque >= 0 && item.calcEmbarque <= 20){
        return "color: #CED202"
      }else if(item.calcEmbarque < 0){
        return "color: #FF0000"
      }
    },

    getColor(item) {
      switch (item.comparativo) {
        case 0:
          return "#3CA40B";
        case null:
          return "#FAB913";
        default:
          return "#D01F1F";
      }
    },

    getColorEmbarque(item) {
      if (
        item.detPesoFrete == item.embarquePeso &&
        item.detPesoFrete != null &&
        item.embarqueCustoFrete >= 0 &&
        item.detValorFrete >= 0
      ) {
        let vrFreteAdicional = Number(item.detValorFrete * (3 / 100)).toFixed(
          2
        );
        let vrFrete = Number(item.detValorFrete) + Number(vrFreteAdicional);
        if (item.embarqueCustoFrete <= vrFrete) {
          return "#3CA40B";
        } else {
          return "#FAB913";
        }
      } else {
        return "#FAB913";
      }
    },

    getColorConemb(item) {
      if (
        item.embarquePeso == item.pesoPago &&
        item.embarqueCustoFrete == item.custoFretePago &&
        item.embarquePeso != null
      ) {
        return "#3CA40B";
      } else {
        return "#FAB913";
      }
    },

    getContent(item) {
      switch (item.comparativo) {
        case 0:
          return "Ok";
        case null:
          return "Pendente";
        default:
          return "Divergente";
      }
    },

    async checkEmpresa() {
      await this.carregarTransportadoraBanco();
      await this.carregarObjetosEmpresaCinco();
      await this.carregarItemsReverso();
    },

    async carregarTokens() {
      this.transpEmbalamento = []
      await axios
        .get(`${baseApiUrlPersys}/token/${this.codigo_empresa}`, {headers: {'Token-API': this.$store.state.token_persys}})
        .then((res) => {
          this.transpEmbalamento = res.data;
          for (let i = 0; i < res.data.length; i++) {
            if(this.transpEmbalamento[i].tipo == null){
              this.transpEmbalamento[i].tipo = 0
            }
          }
        })
    },

    async carregarItemsReverso() {
      if(this.tabIndexGrid !== 0) return;

      if(this.dataInicio == null || this.dataFinal == null) {
        return;
      }

      this.grid.objetos.itemsReverso = [];
      this.grid.objetos.carregando = true;
      await axios
        .get(`${baseApiUrlPersys}/order/objeto/reverso?fim=${moment(this.dataFinal).format("YYYY-MM-DD")}&inicio=${moment(this.dataInicio).format("YYYY-MM-DD")}&page=${this.grid.objetos.actualPage - 1}&size=${this.grid.objetos.perPage}`, {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          }
        )
        .then((res) => {
          this.grid.objetos.items = res.data.order;
          this.grid.objetos.porPagina = this.grid.objetos.perPage;
          this.grid.objetos.totSize = res.data.size;
          this.grid.objetos.totalPage = Math.ceil(this.grid.objetos.totSize / this.grid.objetos.perPage) < 1 ? 1 : Math.ceil(this.grid.objetos.totSize / this.grid.objetos.perPage);
          for (let i = 0; i < res.data.order.length; i++){
            let nomeTrasportadora = "";
            this.dadosTransportadoraBanco.forEach(element => {
              if(element.codigoSisfrete == this.grid.objetos.items[i].codTransportadora) {
                nomeTrasportadora = element.nome;
              }
            });
            this.grid.objetos.items[i].transportadoraNome = nomeTrasportadora;
            this.grid.objetos.items[i].dataConsiderada = this.grid.objetos.items[i].dataConsiderada
              ? moment(this.grid.objetos.items[i].dataConsiderada).format(
                  "DD/MM/YYYY"
                )
              : "";
            this.grid.objetos.items[i].dataPostagem = this.grid.objetos.items[i].dataPostagem
              ? moment(this.grid.objetos.items[i].dataPostagem).format(
                  "DD/MM/YYYY"
                )
              : "";

              this.grid.objetos.items[i].dataUltimaPosicao = this.grid.objetos.items[i]
              .dataUltimaPosicao
              ? moment(this.grid.objetos.items[i].dataUltimaPosicao).format(
                  "DD/MM/YYYY"
                )
              : "";

            this.grid.objetos.items[i].dataPrevista = this.grid.objetos.items[i]
              .dataPrevista
              ? moment(this.grid.objetos.items[i].dataPrevista).format(
                  "DD/MM/YYYY"
                )
              : "";

            this.grid.objetos.items[i].dataEntrega = this.grid.objetos.items[i]
              .dataEntrega
              ? moment(this.grid.objetos.items[i].dataEntrega).format(
                  "DD/MM/YYYY"
                )
              : "";

            if(this.grid.objetos.items[i].status == "I"){
              this.grid.objetos.items[i].numeroRastreioStatus = "Sem rastreio"
            }else if(this.grid.objetos.items[i].status == "A" && this.grid.objetos.items[i].roubo == 0 && this.grid.objetos.items[i].extravio == 0 && (this.grid.objetos.items[i].dataPrevista.toString().split('/').reverse().join('-') >= moment().format("YYYY-MM-DD") || this.grid.objetos.items[i].dataPrevista == null) && this.grid.objetos.items[i].dataEntrega == null){
              this.grid.objetos.items[i].numeroRastreioStatus = "Em transito"
            }else if(this.grid.objetos.items[i].status == "A" && this.grid.objetos.items[i].roubo == 0 && this.grid.objetos.items[i].extravio == 0 && this.grid.objetos.items[i].dataPrevista.toString().split('/').reverse().join('-') < moment().format("YYYY-MM-DD") && this.grid.objetos.items[i].dataEntrega == null){
              this.grid.objetos.items[i].numeroRastreioStatus = "Em atraso"
            }else if(this.grid.objetos.items[i].status == "A" && this.grid.objetos.items[i].dataEntrega == null && this.grid.objetos.items[i].roubo == 1 || this.grid.objetos.items[i].extravio == 1){
              this.grid.objetos.items[i].numeroRastreioStatus = "Alerta"
            }else if(this.grid.objetos.items[i].status == "D" ){
              this.grid.objetos.items[i].numeroRastreioStatus = "Entregue"
            }else if(this.grid.objetos.items[i].status == "E" && this.grid.objetos.items[i].ultimaPosicao == "Objeto não encontrado na base de dados dos Correios."){
              this.grid.objetos.items[i].numeroRastreioStatus = "Não postado"
            }else if(this.grid.objetos.items[i].status == "E" ){
              this.grid.objetos.items[i].numeroRastreioStatus = "Erro"
            }

            if (this.grid.objetos.items[i].entregaDomicilio == "S") {
              this.grid.objetos.items[i].entregaDomicilio = "Sim"
            }else if (this.grid.objetos.items[i].entregaDomicilio == null){
              this.grid.objetos.items[i].entregaDomicilio = "Não"
            }else if (this.grid.objetos.items[i].entregaDomicilio == ""){
              this.grid.objetos.items[i].entregaDomicilio = "Não"
            }else if (this.grid.objetos.items[i].entregaDomicilio == "N"){
              this.grid.objetos.items[i].entregaDomicilio = "Não"
            }
          }
        })
        .catch(() => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        })
        .finally(() => {
          this.grid.objetos.carregando = false;
        })
    },

    async carregarObjetosEmpresaCinco() {
      this.lastTypeSearch = 0;
      if(this.tabIndexGrid == 0) return;

      let dataInicial = "";
      let dataFinal = "";
      let url = `${baseApiUrlPersys}/order/objeto/full?`;
      if(this.dataInicio != null && this.dataFinal != null) {
        dataInicial = moment(this.dataInicio).format("YYYY-MM-DD");
        dataFinal = moment(this.dataFinal).format("YYYY-MM-DD");
        url += `fim=${dataFinal}&inicio=${dataInicial}&`;
      }

      this.modeloPaginacaoAtual = 0;
      this.grid.objetos.items = [];
      this.grid.objetos.carregando = true;
      await axios
        .post(
          `${url}page=${this.grid.objetos.actualPage - 1}&size=${this.grid.objetos.perPage}&type=${this.tabIndexGrid}`, {}, {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          }
        )
        .then((res) => {
          this.limparFiltros();
          this.grid.objetos.items = res.data.order;
          this.grid.objetos.porPagina = this.grid.objetos.perPage;
          this.grid.objetos.totSize = res.data.size;
          this.grid.objetos.totalPage = Math.ceil(this.grid.objetos.totSize / this.grid.objetos.perPage) < 1 ? 1 : Math.ceil(this.grid.objetos.totSize / this.grid.objetos.perPage);
          for (let i = 0; i < res.data.order.length; i++) {
            if (!this.canais.includes(this.grid.objetos.items[i].canalVenda)) {
              this.canais.push(this.grid.objetos.items[i].canalVenda);
            }
            if(this.grid.objetos.items[i].subCanal != null) {
              if (!this.subcanais.includes(this.grid.objetos.items[i].subCanal)) {
                if(this.subcanais.length == 0) {
                  this.subcanais.push('Todos');
                }
                this.subcanais.push(this.grid.objetos.items[i].subCanal);
              }
            }
            if (!this.ufs.includes(this.grid.objetos.items[i].destinatarioEstado)) {
              if(this.ufs.length == 0) {
                this.ufs.push('Todos');
              }
              this.ufs.push(this.grid.objetos.items[i].destinatarioEstado);
            }
            if (
              this.grid.objetos.items[i].custoFretePago == null ||
              this.grid.objetos.items[i].custoFreteTabela == null
            ) {
              if (this.grid.objetos.items[i].custoFretePago == null) {
                this.grid.objetos.items[i].divergenciaValor =
                  "Aguardando conemb";
              } else {
                this.grid.objetos.items[i].divergenciaValor =
                  "Aguardando frete tabela";
              }
            } else {
              this.grid.objetos.items[i].divergenciaValor = Number(
                this.grid.objetos.items[i].custoFretePago -
                  this.grid.objetos.items[i].custoFreteTabela
              ).toFixed(2);
            }
            if (
              this.grid.objetos.items[i].embarqueCustoFrete == null ||
              this.grid.objetos.items[i].custoFretePago == null
            ) {
              if (this.grid.objetos.items[i].custoFretePago == null) {
                this.grid.objetos.items[i].divergenciaPagamento =
                  "Aguardando conemb";
              } else {
                this.grid.objetos.items[i].divergenciaPagamento =
                  "Aguardando embarque";
              }
            } else {
              this.grid.objetos.items[i].divergenciaPagamento = Number(
                this.grid.objetos.items[i].embarqueCustoFrete -
                  this.grid.objetos.items[i].custoFretePago
              ).toFixed(2);
            }
            if (
              this.grid.objetos.items[i].detValorFrete == null ||
              this.grid.objetos.items[i].embarqueCustoFrete == null
            ) {
              if (
                this.grid.objetos.items[i].detValorFrete == null &&
                this.grid.objetos.items[i].embarqueCustoFrete == null
              ) {
                this.grid.objetos.items[i].divergenciaPeso =
                  "Aguardando cotação";
              } else if (
                this.grid.objetos.items[i].embarqueCustoFrete == null
              ) {
                this.grid.objetos.items[i].divergenciaPeso =
                  "Aguardando embarque";
              } else {
                this.grid.objetos.items[i].divergenciaPeso =
                  "Aguardando cotação";
              }
            } else {
              this.grid.objetos.items[i].divergenciaPeso = Number(
                this.grid.objetos.items[i].detValorFrete -
                  this.grid.objetos.items[i].embarqueCustoFrete
              ).toFixed(2);
            }
            this.grid.objetos.items[i].dataVenda = this.grid.objetos.items[i]
              .dataVenda
              ? moment(this.grid.objetos.items[i].dataVenda).format(
                  "DD/MM/YYYY"
                )
              : "";
            this.grid.objetos.items[i].dataUltimaPosicao = this.grid.objetos.items[i]
              .dataUltimaPosicao
              ? moment(this.grid.objetos.items[i].dataUltimaPosicao).format(
                  "DD/MM/YYYY"
                )
              : "";

            this.grid.objetos.items[i].dataPrevista = this.grid.objetos.items[i]
              .dataPrevista
              ? moment(this.grid.objetos.items[i].dataPrevista).format(
                  "DD/MM/YYYY"
                )
              : "";

            this.grid.objetos.items[i].percDifal =
              this.grid.objetos.items[i].detDifalFrete == null
                ? "0%"
                : Number(
                    (this.grid.objetos.items[i].detDifalFrete /
                      (this.grid.objetos.items[i].valorProdutos +
                        this.grid.objetos.items[i].valorFrete)) *
                      100
                  ).toFixed(2);
            this.grid.objetos.items[i].percGanho =
              this.grid.objetos.items[i].detGanhoFrete == null ||
              this.grid.objetos.items[i].detValorFrete == null
                ? "Aguardando cotação"
                : Number(
                    (this.grid.objetos.items[i].detGanhoFrete /
                      this.grid.objetos.items[i].detValorFrete) *
                      100
                  ).toFixed(2);
            if (this.grid.objetos.items[i].embarqueCustoFrete == null) {
              this.grid.objetos.items[i].percEmbarque = "Aguardando embarque";
            } else if (this.grid.objetos.items[i].detValorFrete == null) {
              this.grid.objetos.items[i].percEmbarque = "Aguardando cotação";
            } else {
              this.grid.objetos.items[i].percEmbarque = Number(
                (this.grid.objetos.items[i].embarqueCustoFrete /
                  this.grid.objetos.items[i].detValorFrete) *
                  100 -
                  100
              ).toFixed(2);
            }
            if (
              this.grid.objetos.items[i].detValorFrete == null ||
              this.grid.objetos.items[i].detGanhoFrete == null ||
              this.grid.objetos.items[i].detTaxaFrete == null
            ) {
              this.grid.objetos.items[i].lucroFinal = "Aguardando cotação";
            } else if (this.grid.objetos.items[i].custoFretePago == null) {
              this.grid.objetos.items[i].lucroFinal = "Aguardando conemb";
            } else {
              this.grid.objetos.items[i].lucroFinal = Number(
                this.grid.objetos.items[i].detValorFrete -
                  this.grid.objetos.items[i].custoFretePago +
                  (this.grid.objetos.items[i].detGanhoFrete -
                    this.grid.objetos.items[i].detTaxaFrete)
              ).toFixed(2);
            }
            if (
              this.grid.objetos.items[i].lucroFinal == "Aguardando cotação" ||
              this.grid.objetos.items[i].lucroFinal == "Aguardando conemb"
            ) {
              this.grid.objetos.items[i].percLucroFrete = "Aguardando conemb";
            } else if (
              this.grid.objetos.items[i].detTaxaFrete == null ||
              this.grid.objetos.items[i].detDifalFrete == null
            ) {
              this.grid.objetos.items[i].percLucroFrete = "Aguardando cotação";
            } else {
              this.grid.objetos.items[i].percLucroFrete = Number(
                (this.grid.objetos.items[i].lucroFinal /
                  (this.grid.objetos.items[i].valorFrete -
                    this.grid.objetos.items[i].detTaxaFrete -
                    this.grid.objetos.items[i].detDifalFrete)) *
                  100
              ).toFixed(2);
            }

            if(this.grid.objetos.items[i].status == "I"){
              this.grid.objetos.items[i].numeroRastreioStatus = "Sem rastreio"
            }else if(this.grid.objetos.items[i].status == "A" && this.grid.objetos.items[i].roubo == 0 && this.grid.objetos.items[i].extravio == 0 && (this.grid.objetos.items[i].dataPrevista.toString().split('/').reverse().join('-') >= moment().format("YYYY-MM-DD") || this.grid.objetos.items[i].dataPrevista == null) && this.grid.objetos.items[i].dataEntrega == null){
              this.grid.objetos.items[i].numeroRastreioStatus = "Em transito"
            }else if(this.grid.objetos.items[i].status == "A" && this.grid.objetos.items[i].roubo == 0 && this.grid.objetos.items[i].extravio == 0 && this.grid.objetos.items[i].dataPrevista.toString().split('/').reverse().join('-') < moment().format("YYYY-MM-DD") && this.grid.objetos.items[i].dataEntrega == null){
              this.grid.objetos.items[i].numeroRastreioStatus = "Em atraso"
            }else if(this.grid.objetos.items[i].status == "A" && this.grid.objetos.items[i].dataEntrega == null && this.grid.objetos.items[i].roubo == 1 || this.grid.objetos.items[i].extravio == 1){
              this.grid.objetos.items[i].numeroRastreioStatus = "Alerta"
            }else if(this.grid.objetos.items[i].status == "D" ){
              this.grid.objetos.items[i].numeroRastreioStatus = "Entregue"
            }else if(this.grid.objetos.items[i].status == "E" && this.grid.objetos.items[i].ultimaPosicao == "Objeto não encontrado na base de dados dos Correios."){
              this.grid.objetos.items[i].numeroRastreioStatus = "Não postado"
            }else if(this.grid.objetos.items[i].status == "E" ){
              this.grid.objetos.items[i].numeroRastreioStatus = "Erro"
            }


            if(this.grid.objetos.items[i].comparativo == null){
              this.grid.objetos.items[i].itemStatus = "Aguardando Cotação"
            } else if (this.grid.objetos.items[i].embarquePeso == null && this.grid.objetos.items[i].embarqueCustoFrete == null){
              this.grid.objetos.items[i].itemStatus = "Aguardando Embalamento"
            } else if (this.grid.objetos.items[i].numeroRastreio == null){
              this.grid.objetos.items[i].itemStatus = "Aguardando número de rastreio"
            } else if (this.grid.objetos.items[i].custoFretePago == null && this.grid.objetos.items[i].pesoPago == null || this.grid.objetos.items[i].custoFreteTabela == null){
              this.grid.objetos.items[i].itemStatus = "Aguardando Conemb"
            } else if (this.grid.objetos.items[i].comparativo != null && this.grid.objetos.items[i].embarquePeso != null && this.grid.objetos.items[i].embarqueCustoFrete != null && this.grid.objetos.items[i].numeroRastreio != null && this.grid.objetos.items[i].custoFretePago != null && this.grid.objetos.items[i].pesoPago != null && this.grid.objetos.items[i].custoFreteTabela != null){
              this.grid.objetos.items[i].itemStatus = "Concluído"
            }

            if(this.grid.objetos.items[i].embarquePeso == null || this.grid.objetos.items[i].pesoPago == null){
              this.grid.objetos.items[i].calcPeso = "0.000"
            }else{
              this.grid.objetos.items[i].calcPeso = ((this.grid.objetos.items[i].embarquePeso - this.grid.objetos.items[i].pesoPago)).toFixed(3);
            }

            if(this.grid.objetos.items[i].calcPeso == "-0.000"){
              this.grid.objetos.items[i].calcPeso = "0.000"
            }

            if (this.grid.objetos.items[i].entregaDomicilio == "S") {
              this.grid.objetos.items[i].entregaDomicilio = "Sim"
            }else if (this.grid.objetos.items[i].entregaDomicilio == null){
              this.grid.objetos.items[i].entregaDomicilio = "Não"
            }else if (this.grid.objetos.items[i].entregaDomicilio == ""){
              this.grid.objetos.items[i].entregaDomicilio = "Não"
            }

            this.grid.objetos.items[i].calcVenda = ((this.grid.objetos.items[i].detGanhoFrete - this.grid.objetos.items[i].detTaxaFrete) / this.grid.objetos.items[i].detValorFrete * 100).toFixed(2);
            if(this.grid.objetos.items[i].custoFreteTabela == null || this.grid.objetos.items[i].custoFretePago == null) {
              this.grid.objetos.items[i].calcTabela = Number(0).toFixed(2);
            } else {
              this.grid.objetos.items[i].calcTabela = ((this.grid.objetos.items[i].custoFreteTabela - this.grid.objetos.items[i].custoFretePago)).toFixed(2);
            }
            this.grid.objetos.items[i].calcEmbarque = Number(this.grid.objetos.items[i].detValorFrete - this.grid.objetos.items[i].embarqueCustoFrete + (this.grid.objetos.items[i].detGanhoFrete - this.grid.objetos.items[i].detTaxaFrete) / this.grid.objetos.items[i].embarqueCustoFrete * 100).toFixed(2);
            this.grid.objetos.items[i].calcAuditoria = ((this.grid.objetos.items[i].detValorFrete - this.grid.objetos.items[i].custoFretePago + (this.grid.objetos.items[i].detGanhoFrete - this.grid.objetos.items[i].detTaxaFrete)) / this.grid.objetos.items[i].custoFretePago * 100).toFixed(2);

             if (this.grid.objetos.items[i].calcAuditoria == "Infinity" || this.grid.objetos.items[i].calcAuditoria == "-Infinity" || this.grid.objetos.items[i].calcAuditoria == "NaN"){
              this.grid.objetos.items[i].calcAuditoria = ""
             }else{
              this.grid.objetos.items[i].calcAuditoria = this.grid.objetos.items[i].calcAuditoria
             }

            if (this.grid.objetos.items[i].calcVenda == "Infinity" || this.grid.objetos.items[i].calcVenda == "-Infinity" || this.grid.objetos.items[i].calcVenda == "NaN"){
              this.grid.objetos.items[i].calcVenda = ""
             }else{
              this.grid.objetos.items[i].calcVenda = this.grid.objetos.items[i].calcVenda
             }

            if (this.grid.objetos.items[i].calcTabela == "Infinity" || this.grid.objetos.items[i].calcTabela == "-Infinity" || this.grid.objetos.items[i].calcTabela == "NaN"){
              this.grid.objetos.items[i].calcTabela = ""
             }else{
              this.grid.objetos.items[i].calcTabela = this.grid.objetos.items[i].calcTabela
             }

            if (this.grid.objetos.items[i].calcEmbarque == "Infinity" || this.grid.objetos.items[i].calcEmbarque == "-Infinity" || this.grid.objetos.items[i].calcEmbarque == "NaN"){
              this.grid.objetos.items[i].calcEmbarque = ""
             }else{
              this.grid.objetos.items[i].calcEmbarque = this.grid.objetos.items[i].calcEmbarque
             }

            this.grid.objetos.items[i].difal = Number((this.grid.objetos.items[i].detDifalFrete / (this.grid.objetos.items[i].valorProdutos + this.grid.objetos.items[i].valorFrete)) * 100).toFixed(2) + '%';

          }
          this.grid.objetos.carregando = false;
          this.backupItems = JSON.parse(JSON.stringify(this.grid.objetos.items));
        })
        .catch(() => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        })
        .finally(()=> {
        });
    },

    changeCabecalho() {
      this.coluna = "objetos.numeroPedido";
      return this.grid.objetos.cabecalhoEmpresaCinco;
    },

    checkNameItems() {
      return "cabecalhoEmpresaCinco";
    },

    limparFiltros() {
      this.filtros.data_inicio = null;
      this.filtros.data_final = null;
      this.filtros.allDates = true;
      this.filtros.cod_transportadora = null;
      this.filtros.subCanal = null;
      this.filtros.estado = null;
      this.filtros.difal = null;
      this.filtros.codigo = null;
      this.filtros.cep_origem = null;
      this.filtros.cep_destino = null;
      this.filtros.numero_rastreio = null;
      this.filtros.prazo = null;
      this.filtros.dt_prevista = null;
      this.filtros.dt_considerada = null;
      this.filtros.dt_entrega = null;
      this.filtros.dt_ultima_posicao = null;
    },
    btnReclama() {
      this.modal.pi = true;
    },
    fechar() {
      this.$router.push("/");
    },
    carregarObjetos() {
      this.grid.objetos.items = [];
      this.grid.objetos.carregando = true;
      let url = `${baseApiUrl}/objetos?page=${this.grid.objetos.paginaAtual}`;
      url += `&coluna=${this.grid.objetos.coluna}&direcao=${this.grid.objetos.direcao}`;
      if (this.filtros.codigo != "") url += `&codigo=${this.filtros.codigo}`;
      if (this.filtros.cod_transportadora != "")
        url += `&cod_transportadora=${this.filtros.cod_transportadora}`;
      if (this.filtros.numero_rastreio != "")
        url += `&numero_rastreio=${this.filtros.numero_rastreio}`;
      if (this.filtros.prazo != "")
        url += `&prazo_entrega=${this.filtros.prazo}`;
      if (this.filtros.dt_considerada != "")
        url += `&data_considerada=${this.filtros.dt_considerada}`;
      if (this.filtros.dt_prevista != "")
        url += `&data_prevista=${this.filtros.dt_prevista}`;
      if (this.filtros.dt_entrega != "")
        url += `&data_entrega=${this.filtros.dt_entrega}`;
      if (this.filtros.cep_origem != "")
        url += `&remetente_cep=${this.filtros.cep_origem}`;
      if (this.filtros.cep_destino != "")
        url += `&destinatario_cep=${this.filtros.cep_destino}`;
      if (this.filtros.dt_ultima_posicao != "")
        url += `&data_ultima_posicao=${this.filtros.dt_ultima_posicao}`;
      if (this.filtros.ultima_posicao != "")
        url += `&ultima_posicao=${this.filtros.ultima_posicao}`;
      if (Number(this.grid.objetos.porPagina) > 0)
        url += `&por_pagina=${this.grid.objetos.porPagina}`;
      if (localStorage.getItem("factory"))
        url += `&cod_empresa=${localStorage.getItem("factory")}`;

      axios
        .get(url)
        .then((res) => {
          this.grid.objetos.items = res.data.dados.data;
          this.grid.objetos.totalRegistros = res.data.dados.total;
          this.grid.objetos.porPagina = Number(res.data.dados.per_page);
        })
        .finally(() => (this.grid.objetos.carregando = false));
    },
    mudarPagina(pagina) {
      this.carregarObjetosEmpresaCinco();
    },
    carregarTransportadoras() {
      let url = `${baseApiUrl}/transportadoras`;
      axios.get(url).then((res) => {
        this.transportadoras = res.data.transportadoras;
      });
    },
    carregarTransportadorasPersys() {
      let url = `${baseApiUrlPersys}/transportadorasSisfrete`;
      axios.get(url).then((res) => {
        let arrTransportadoras = res.data;
        const arrTransportadorasFiltrado = arrTransportadoras.filter(transportadora => {
          // Verifica se o ID da transportadora está presente em outroArray
          return this.transportadorasCodigo.includes(transportadora.codigo);
        });
        this.dadosTransportadoraPersysAPI = arrTransportadorasFiltrado;
      });
    },
    voltarTopo() {
      window.scrollTo(0, 0);
    },
    formatarNumeroBR(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatarDataBR(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/yyyy");
      }
    },
    async carregarManifesto(item) {
      this.grid.manifesto.carregando = true;
      let url = `${baseApiUrl}/manifesto`;
      url += `?numero_pi=${item}`;
      url += `&cod_empresa=${localStorage.getItem("factory")}`;
      await axios
        .get(url)
        .then((res) => {
          this.grid.manifesto.items = res.data.dados;
        })
        .finally(() => (this.grid.manifesto.carregando = false));
      this.grid.manifesto.carregando = false;
    },
    async carregarRastreioObjetos(item) {
      this.grid.detalhes.carregando = true;
      let url = `${baseApiUrl}/rastreios?numero_rastreio=${
        item.numero_rastreio ? item.numero_rastreio : item.numeroRastreio
      }`;
      url += `&cod_empresa=${localStorage.getItem("factory")}`;
      await axios
        .get(url)
        .then((res) => {
          this.grid.detalhes.items = res.data.dados;
        })
        .finally(() => (this.grid.detalhes.carregando = false));
      this.grid.detalhes.carregando = false;
    },

    async carregarDetalhesObjeto(item) {
      this.grid.detalhes.carregando = true;
      let url = `${baseApiUrl}/detalhes?numero_rastreio=${
        item.numero_rastreio ? item.numero_rastreio : item.numeroRastreio
      }`;
      await axios
        .get(url)
        .then((res) => {
          if (item.numero_rastreio) {
            this.detalhes.enviado.data_postagem = moment(
              res.data.dados[0].data_postagem
            ).format("DD/MM/yyyy");
            this.detalhes.enviado.data_considerada = moment(
              res.data.dados[0].data_considerada
            ).format("DD/MM/yyyy");
            this.detalhes.enviado.cep = res.data.dados[0].remetente_cep;
            this.detalhes.enviado.cidade =
              res.data.dados[0].remetente_cidade +
              "/" +
              res.data.dados[0].remetente_estado;
            this.detalhes.para.nome = res.data.dados[0].destinatario_nome;
            this.detalhes.para.cep = res.data.dados[0].destinatario_cep;
            this.detalhes.para.cidade =
              res.data.dados[0].destinatario_cidade +
              "/" +
              res.data.dados[0].destinatario_estado;
            this.detalhes.para.bairro = res.data.dados[0].destinatario_bairro;
            this.detalhes.para.rua =
              res.data.dados[0].destinatario_rua +
              ", " +
              res.data.dados[0].destinatario_numero;
            this.detalhes.previsao_de_entrega.data = moment(
              res.data.dados[0].data_prevista
            ).format("DD/MM/yyyy");
            this.detalhes.previsao_de_entrega.em_domicilio =
              res.data.dados[0].entrega_domicilio;
            this.detalhes.entregue_em.data =
              res.data.dados[0].data_entrega != null
                ? moment(res.data.dados[0].data_entrega).format("DD/MM/yyyy")
                : "Não entregue";
            this.detalhes.numero_danfe = res.data.dados[0].numero_danfe;
            this.detalhes.order_id = res.data.dados[0].order_id;
            this.detalhes.numero_rastreio = res.data.dados[0].numero_rastreio;

          } else {
            this.detalhes.enviado.data_postagem = moment(
              res.data.dados[0].data_postagem
            ).format("DD/MM/yyyy");
            this.detalhes.enviado.data_considerada = moment(
              res.data.dados[0].data_considerada
            ).format("DD/MM/yyyy");
            this.detalhes.enviado.cep = res.data.dados[0].remetente_cep;
            this.detalhes.enviado.cidade =
              res.data.dados[0].remetente_cidade +
              "/" +
              res.data.dados[0].remetente_estado;
            this.detalhes.para.nome = res.data.dados[0].destinatario_nome;
            this.detalhes.para.cep = res.data.dados[0].destinatario_cep;
            this.detalhes.para.cidade =
              res.data.dados[0].destinatario_cidade +
              "/" +
              res.data.dados[0].destinatario_estado;
            this.detalhes.para.bairro = res.data.dados[0].destinatario_bairro;
            this.detalhes.para.rua =
              res.data.dados[0].destinatario_rua +
              ", " +
              res.data.dados[0].destinatario_numero;
            this.detalhes.previsao_de_entrega.data = moment(
              res.data.dados[0].data_prevista
            ).format("DD/MM/yyyy");
            this.detalhes.previsao_de_entrega.em_domicilio =
              res.data.dados[0].entrega_domicilio;
            this.detalhes.entregue_em.data =
              res.data.dados[0].data_entrega != null
                ? moment(res.data.dados[0].data_entrega).format("DD/MM/yyyy")
                : "Não entregue";
            this.detalhes.numero_danfe = res.data.dados[0].numero_danfe;
            this.detalhes.order_id = res.data.dados[0].order_id;
            this.detalhes.numero_rastreio = res.data.dados[0].numero_rastreio;
            this.detalhes.cod_servico = res.data.dados[0].cod_servico;
            this.detalhes.ultima_posicao = res.data.dados[0].ultima_posicao == null ? 'Sem informação' : res.data.dados[0].ultima_posicao;
            this.detalhes.dt_ultima_posicao = res.data.dados[0].data_ultima_posicao == null ? 'Sem informação' : moment(res.data.dados[0].data_ultima_posicao).format("DD/MM/yyyy");

          }
        })
        .finally(() => (this.grid.detalhes.carregando = false));
      this.grid.detalhes.carregando = false;
    },
    carregarDetalhesBusca(item) {
      this.carregarDetalhes(item);
    },
    async carregarDetalhes(item, tabIndex = 0) {

      this.detalhes.cod_servico = this.detalhes.cod_servico == null ? 'Sem informação' : this.detalhes.cod_servico;

      this.objetoDetalhes.cep_de = this.detalhes.enviado.cep;
      this.objetoDetalhes.cep_para = this.detalhes.para.cep;
      this.objetoDetalhes.cod_servico = this.detalhes.cod_servico
      this.objetoDetalhes.numero_rastreio = this.detalhes.numero_rastreio;


      this.statusObjeto = item.status;
      this.ultimaPosicao = item.ultimaPosicao == "Objeto não encontrado na base de dados dos Correios." ? 'N' : ''
      this.reclamacao.codigo = "";
      this.reclamacao.numero_rastreio = "";
      this.objetoAtual = item.numero_rastreio
        ? item.numero_rastreio
        : item.numeroRastreio;
      this.objeto.numero_rastreio = item.numero_rastreio
        ? item.numero_rastreio
        : item.numeroRastreio;
      this.objeto.protocolo_reclamacao = item.protocolo_reclamacao;
      this.objeto.habilita = item.protocolo_reclamacao > 0;
      this.tabIndex = tabIndex;
      this.modal.detalhes_conferencia = true;


      await this.carregarConferencia(item);
      await this.carregarRastreioObjetos(item);
      await this.carregarDetalhesObjeto(item);
      await this.carregarManifesto(item.protocolo_reclamacao);


    },
    salvarNumeroRastreio(numero_rastreio) {
      this.objeto.rastreio = numero_rastreio;
      this.objeto.numero_rastreio = this.detalhes.numero_rastreio;

      this.grid.detalhes.carregando = true;
      axios
        .post(`${baseApiUrl}/objetos`, this.objeto)
        .then((response) => {
          showSuccess(response.data.mensagem);
          this.modal.detalhes = false;
          this.carregarObjetos();
        })
        .catch((error) => {
          this.grid.detalhes.carregando = false;
          showError(error.data.mensagem);
        })
        .finally(() => (this.grid.detalhes.carregando = false));
    },
    salvarProtocoloReclamacao(nroPi) {
      if (nroPi != "") {
        let resultado = confirm(
          "Gravar protocolo " +
            nroPi +
            " para o objeto " +
            this.objetoAtual +
            " ?"
        );
        if (resultado) {
          this.grid.detalhes.carregando = true;
          axios
            .post(`${baseApiUrl}/objetos`, this.objeto)
            .then((response) => {
              showSuccess(response.data.mensagem);
              this.carregarDetalhes(this.objetoAtual);
            })
            .catch((error) => {
              this.grid.detalhes.carregando = false;
              showError(error.response.data.mensagem);
            })
            .finally(() => (this.grid.detalhes.carregando = false));
        }
      }
    },
    criarReclamacao() {
      if (this.reclamacao.codigo) {
        this.modal.sendComplaint = true;
      } else {
        alert("OBRIGATÓRIO SELECIONAR O MOTIVO DA RECLAMAÇÃO!");
      }
    },
    salvarReclamacao() {
      this.reclamacao.numero_rastreio = this.detalhes.numero_rastreio;
      this.reclamacao.cod_empresa = `${localStorage.getItem("factory")}`;

      this.grid.detalhes.carregando = true;
      axios
        .post(`${baseApiUrl}/reclamacao-send`, this.reclamacao)
        .then((response) => {
          if (response.data.retorno) {
            showSuccess(response.data.mensagem);
            this.modal.detalhes = false;
            this.carregarObjetos();
          } else {
            showError(response.data.mensagem);
            console.log(response.data.debug);
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => (this.grid.detalhes.carregando = false));
    },
    closeModalDetalhesConferencia() {
      this.tabIndex = 0;
      this.conferencia.diferenca_venda = null;
      this.conferencia.diferenca_custo = null;
      this.conferencia.diferenca_peso = null;
      this.conferencia.valor_produtos = null;
      this.conferencia.valor_frete = null;
      this.conferencia.total_frete_conferencia = null;
      this.conferencia.total_peso_conferencia = null;
      this.conferencia.custo_frete = null;
      this.conferencia.difal_frete = null;
      this.conferencia.ganho_frete = null;
      this.conferencia.taxa_frete = null;
      this.conferencia.lucro_frete = null;
      this.conferencia.embarque_peso = null;
      this.conferencia.embarque_custo_frete = null;
      this.conferencia.custo_frete_pago = null;
      this.conferencia.custo_frete_tabela = null;
      this.conferencia.numero_dacte = null;
      this.conferencia.peso_pago = null;
      this.conferencia.jsonProdutos = null;
      this.conferencia.jsonSisFrete = null;
      this.conferencia.jsonSisFreteRequisicao = null;
      this.conferencia.jsonStatus = null;
      this.conferencia.jsonSisFreteEmbalamento = null;
      this.conferencia.jsonSisFreteEmbalamentoRequisicao = null;
      this.conferencia.numero_pedido = null;
      this.conferencia.calculo_reverso = null;
      this.modal.detalhes_conferencia = false;
    },
    itemStyle(item) {
      return item.Base == this.conferencia.base_transportadora ? 'itemGridGreenAndBold' : '';
    },
    save () {
      // console.log(this.objetoDetalhes)
      axios.post(`${baseApiUrl}/objetos`, this.objetoDetalhes)
      .then(response => {
        // console.log(response);
        showSuccess(response.data.mensagem)
        this.modal.detalhes_conferencia = false
        this.carregarObjetosEmpresaCinco()
      })
      .catch(error => {
        showError(error.response.data.mensagem)
      })
    },
    async buscarObjetos (fisrtPage  = true) {
      this.lastTypeSearch = 1;
      if(this.tabIndexGrid == 0) this.tabIndexGrid = 1;
      if(fisrtPage)
        this.grid.objetos.actualPage = 1;
      this.modeloPaginacaoAtual = 1;
      let objAux = {};

      let temFiltroInformado = false;

      if(this.filtros.cod_transportadora != null) {
        objAux.codTransportadora = this.filtros.cod_transportadora;
        temFiltroInformado = true;
      }

      if(this.filtros.subCanal != null) {
        objAux.subCanal = this.filtros.subCanal;
        temFiltroInformado = true;
      }

      if(this.filtros.estado != null) {
        objAux.destinatarioEstado = this.filtros.estado;
        temFiltroInformado = true;
      }

      if(this.filtros.difal == 1 || this.filtros.difal == 2) {
        objAux.detBaseDifal = this.filtros.difal == 1 ? true : false;
        temFiltroInformado = true;
      }

      if(this.filtros.codigo != null && this.filtros.codigo != "") {
        objAux.codigo = this.filtros.codigo;
        temFiltroInformado = true;
      }

      if(this.filtros.cep_origem != null && this.filtros.cep_origem != "") {
        objAux.remententeCEP = this.filtros.cep_origem;
        temFiltroInformado = true;
      }

      if(this.filtros.cep_destino != null && this.filtros.cep_destino != "") {
        objAux.destinatarioCep = this.filtros.cep_destino;
        temFiltroInformado = true;
      }

      if(this.filtros.numero_rastreio != null && this.filtros.numero_rastreio != "") {
        objAux.rastreio = this.filtros.numero_rastreio;
        temFiltroInformado = true;
      }

      if(this.filtros.prazo != null && this.filtros.prazo != "") {
        objAux.prazoEntrega = this.filtros.prazo;
        temFiltroInformado = true;
      }

      if(this.filtros.dt_prevista != null) {
        objAux.dataPrevista = this.filtros.dt_prevista;
        temFiltroInformado = true;
      }

      if(this.filtros.dt_considerada != null) {
        objAux.dataConsiderada = this.filtros.dt_considerada;
        temFiltroInformado = true;
      }

      if(this.filtros.dt_ultima_posicao != null) {
        objAux.dataUltimaPosicao = this.filtros.dt_ultima_posicao;
        temFiltroInformado = true;
      }

      if(this.filtros.dt_entrega != null) {
        objAux.dataEntrega = this.filtros.dt_entrega;
        temFiltroInformado = true;
      }

      let dataInicial = "";
      let dataFinal = "";
      let url = `${baseApiUrlPersys}/order/objeto/full?`;
      if(this.filtros.data_inicio != null && this.filtros.data_final != null) {
        dataInicial = moment(this.filtros.data_inicio).format("YYYY-MM-DD");
        dataFinal = moment(this.filtros.data_final).format("YYYY-MM-DD");
        url += `fim=${dataFinal}&inicio=${dataInicial}&`
      }

      url += `page=${this.grid.objetos.actualPage - 1}&size=${this.grid.objetos.perPage}&type=${this.tabIndexGrid}`;

      // let msg = "Informe ";

      // if((dataInicial == "" || dataFinal == "") && !this.filtros.allDates) {
      //   msg += "as datas ";
      // }

      let msg = "";
      if(!temFiltroInformado)
        msg = "Informe pelo menos um filtro para continuar.";

      if(msg != "") {
        return this.$swal({
          title: 'Aviso',
          text: msg,
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
        });
      }

      this.pesquisando = true;
      axios.post(url, objAux, {
          headers: {
            'Token-API': this.$store.state.token_persys
          }
        }
      )
      .then(res => {
        this.grid.objetos.items = res.data.order;
          this.grid.objetos.porPagina = this.grid.objetos.perPage;
          this.grid.objetos.totSize = res.data.size;
          this.grid.objetos.totalPage = Math.ceil(this.grid.objetos.totSize / this.grid.objetos.perPage) < 1 ? 1 : Math.ceil(this.grid.objetos.totSize / this.grid.objetos.perPage);
          for (let i = 0; i < res.data.order.length; i++) {
            if (!this.canais.includes(this.grid.objetos.items[i].canalVenda)) {
              this.canais.push(this.grid.objetos.items[i].canalVenda);
            }
            if(this.grid.objetos.items[i].subCanal != null) {
              if (!this.subcanais.includes(this.grid.objetos.items[i].subCanal)) {
                if(this.subcanais.length == 0) {
                  this.subcanais.push('Todos');
                }
                this.subcanais.push(this.grid.objetos.items[i].subCanal);
              }
            }
            if (!this.ufs.includes(this.grid.objetos.items[i].destinatarioEstado)) {
              if(this.ufs.length == 0) {
                this.ufs.push('Todos');
              }
              this.ufs.push(this.grid.objetos.items[i].destinatarioEstado);
            }
            if (
              this.grid.objetos.items[i].custoFretePago == null ||
              this.grid.objetos.items[i].custoFreteTabela == null
            ) {
              if (this.grid.objetos.items[i].custoFretePago == null) {
                this.grid.objetos.items[i].divergenciaValor =
                  "Aguardando conemb";
              } else {
                this.grid.objetos.items[i].divergenciaValor =
                  "Aguardando frete tabela";
              }
            } else {
              this.grid.objetos.items[i].divergenciaValor = Number(
                this.grid.objetos.items[i].custoFretePago -
                  this.grid.objetos.items[i].custoFreteTabela
              ).toFixed(2);
            }
            if (
              this.grid.objetos.items[i].embarqueCustoFrete == null ||
              this.grid.objetos.items[i].custoFretePago == null
            ) {
              if (this.grid.objetos.items[i].custoFretePago == null) {
                this.grid.objetos.items[i].divergenciaPagamento =
                  "Aguardando conemb";
              } else {
                this.grid.objetos.items[i].divergenciaPagamento =
                  "Aguardando embarque";
              }
            } else {
              this.grid.objetos.items[i].divergenciaPagamento = Number(
                this.grid.objetos.items[i].embarqueCustoFrete -
                  this.grid.objetos.items[i].custoFretePago
              ).toFixed(2);
            }
            if (
              this.grid.objetos.items[i].detValorFrete == null ||
              this.grid.objetos.items[i].embarqueCustoFrete == null
            ) {
              if (
                this.grid.objetos.items[i].detValorFrete == null &&
                this.grid.objetos.items[i].embarqueCustoFrete == null
              ) {
                this.grid.objetos.items[i].divergenciaPeso =
                  "Aguardando cotação";
              } else if (
                this.grid.objetos.items[i].embarqueCustoFrete == null
              ) {
                this.grid.objetos.items[i].divergenciaPeso =
                  "Aguardando embarque";
              } else {
                this.grid.objetos.items[i].divergenciaPeso =
                  "Aguardando cotação";
              }
            } else {
              this.grid.objetos.items[i].divergenciaPeso = Number(
                this.grid.objetos.items[i].detValorFrete -
                  this.grid.objetos.items[i].embarqueCustoFrete
              ).toFixed(2);
            }
            this.grid.objetos.items[i].dataVenda = this.grid.objetos.items[i]
              .dataVenda
              ? moment(this.grid.objetos.items[i].dataVenda).format(
                  "DD/MM/YYYY"
                )
              : "";
            this.grid.objetos.items[i].dataUltimaPosicao = this.grid.objetos.items[i]
              .dataUltimaPosicao
              ? moment(this.grid.objetos.items[i].dataUltimaPosicao).format(
                  "DD/MM/YYYY"
                )
              : "";

            this.grid.objetos.items[i].dataPrevista = this.grid.objetos.items[i]
              .dataPrevista
              ? moment(this.grid.objetos.items[i].dataPrevista).format(
                  "DD/MM/YYYY"
                )
              : "";

            this.grid.objetos.items[i].percDifal =
              this.grid.objetos.items[i].detDifalFrete == null
                ? "0%"
                : Number(
                    (this.grid.objetos.items[i].detDifalFrete /
                      (this.grid.objetos.items[i].valorProdutos +
                        this.grid.objetos.items[i].valorFrete)) *
                      100
                  ).toFixed(2);
            this.grid.objetos.items[i].difal = Number((this.grid.objetos.items[i].detDifalFrete / (this.grid.objetos.items[i].valorProdutos + this.grid.objetos.items[i].valorFrete)) * 100).toFixed(2) + '%';
            this.grid.objetos.items[i].percGanho =
              this.grid.objetos.items[i].detGanhoFrete == null ||
              this.grid.objetos.items[i].detValorFrete == null
                ? "Aguardando cotação"
                : Number(
                    (this.grid.objetos.items[i].detGanhoFrete /
                      this.grid.objetos.items[i].detValorFrete) *
                      100
                  ).toFixed(2);
            if (this.grid.objetos.items[i].embarqueCustoFrete == null) {
              this.grid.objetos.items[i].percEmbarque = "Aguardando embarque";
            } else if (this.grid.objetos.items[i].detValorFrete == null) {
              this.grid.objetos.items[i].percEmbarque = "Aguardando cotação";
            } else {
              this.grid.objetos.items[i].percEmbarque = Number(
                (this.grid.objetos.items[i].embarqueCustoFrete /
                  this.grid.objetos.items[i].detValorFrete) *
                  100 -
                  100
              ).toFixed(2);
            }
            if (
              this.grid.objetos.items[i].detValorFrete == null ||
              this.grid.objetos.items[i].detGanhoFrete == null ||
              this.grid.objetos.items[i].detTaxaFrete == null
            ) {
              this.grid.objetos.items[i].lucroFinal = "Aguardando cotação";
            } else if (this.grid.objetos.items[i].custoFretePago == null) {
              this.grid.objetos.items[i].lucroFinal = "Aguardando conemb";
            } else {
              this.grid.objetos.items[i].lucroFinal = Number(
                this.grid.objetos.items[i].detValorFrete -
                  this.grid.objetos.items[i].custoFretePago +
                  (this.grid.objetos.items[i].detGanhoFrete -
                    this.grid.objetos.items[i].detTaxaFrete)
              ).toFixed(2);
            }
            if (
              this.grid.objetos.items[i].lucroFinal == "Aguardando cotação" ||
              this.grid.objetos.items[i].lucroFinal == "Aguardando conemb"
            ) {
              this.grid.objetos.items[i].percLucroFrete = "Aguardando conemb";
            } else if (
              this.grid.objetos.items[i].detTaxaFrete == null ||
              this.grid.objetos.items[i].detDifalFrete == null
            ) {
              this.grid.objetos.items[i].percLucroFrete = "Aguardando cotação";
            } else {
              this.grid.objetos.items[i].percLucroFrete = Number(
                (this.grid.objetos.items[i].lucroFinal /
                  (this.grid.objetos.items[i].valorFrete -
                    this.grid.objetos.items[i].detTaxaFrete -
                    this.grid.objetos.items[i].detDifalFrete)) *
                  100
              ).toFixed(2);
            }

            if(this.grid.objetos.items[i].status == "I"){
              this.grid.objetos.items[i].numeroRastreioStatus = "Sem rastreio"
            }else if(this.grid.objetos.items[i].status == "A" && this.grid.objetos.items[i].roubo == 0 && this.grid.objetos.items[i].extravio == 0 && (this.grid.objetos.items[i].dataPrevista.toString().split('/').reverse().join('-') >= moment().format("YYYY-MM-DD") || this.grid.objetos.items[i].dataPrevista == null) && this.grid.objetos.items[i].dataEntrega == null){
              this.grid.objetos.items[i].numeroRastreioStatus = "Em transito"
            }else if(this.grid.objetos.items[i].status == "A" && this.grid.objetos.items[i].roubo == 0 && this.grid.objetos.items[i].extravio == 0 && this.grid.objetos.items[i].dataPrevista.toString().split('/').reverse().join('-') < moment().format("YYYY-MM-DD") && this.grid.objetos.items[i].dataEntrega == null){
              this.grid.objetos.items[i].numeroRastreioStatus = "Em atraso"
            }else if(this.grid.objetos.items[i].status == "A" && this.grid.objetos.items[i].dataEntrega == null && this.grid.objetos.items[i].roubo == 1 || this.grid.objetos.items[i].extravio == 1){
              this.grid.objetos.items[i].numeroRastreioStatus = "Alerta"
            }else if(this.grid.objetos.items[i].status == "D" ){
              this.grid.objetos.items[i].numeroRastreioStatus = "Entregue"
            }else if(this.grid.objetos.items[i].status == "E" && this.grid.objetos.items[i].ultimaPosicao == "Objeto não encontrado na base de dados dos Correios."){
              this.grid.objetos.items[i].numeroRastreioStatus = "Não postado"
            }else if(this.grid.objetos.items[i].status == "E" ){
              this.grid.objetos.items[i].numeroRastreioStatus = "Erro"
            }


            if(this.grid.objetos.items[i].comparativo == null){
              this.grid.objetos.items[i].itemStatus = "Aguardando Cotação"
            } else if (this.grid.objetos.items[i].embarquePeso == null && this.grid.objetos.items[i].embarqueCustoFrete == null){
              this.grid.objetos.items[i].itemStatus = "Aguardando Embalamento"
            } else if (this.grid.objetos.items[i].numeroRastreio == null){
              this.grid.objetos.items[i].itemStatus = "Aguardando número de rastreio"
            } else if (this.grid.objetos.items[i].custoFretePago == null && this.grid.objetos.items[i].pesoPago == null || this.grid.objetos.items[i].custoFreteTabela == null){
              this.grid.objetos.items[i].itemStatus = "Aguardando Conemb"
            } else if (this.grid.objetos.items[i].comparativo != null && this.grid.objetos.items[i].embarquePeso != null && this.grid.objetos.items[i].embarqueCustoFrete != null && this.grid.objetos.items[i].numeroRastreio != null && this.grid.objetos.items[i].custoFretePago != null && this.grid.objetos.items[i].pesoPago != null && this.grid.objetos.items[i].custoFreteTabela != null){
              this.grid.objetos.items[i].itemStatus = "Concluído"
            }

            if(this.grid.objetos.items[i].embarquePeso == null || this.grid.objetos.items[i].pesoPago == null){
              this.grid.objetos.items[i].calcPeso = "0.000"
            }else{
              this.grid.objetos.items[i].calcPeso = ((this.grid.objetos.items[i].embarquePeso - this.grid.objetos.items[i].pesoPago)).toFixed(3);
            }

            if(this.grid.objetos.items[i].calcPeso == "-0.000"){
              this.grid.objetos.items[i].calcPeso = "0.000"
            }

            if (this.grid.objetos.items[i].entregaDomicilio == "S") {
              this.grid.objetos.items[i].entregaDomicilio = "Sim"
            }else if (this.grid.objetos.items[i].entregaDomicilio == null){
              this.grid.objetos.items[i].entregaDomicilio = "Não"
            }else if (this.grid.objetos.items[i].entregaDomicilio == ""){
              this.grid.objetos.items[i].entregaDomicilio = "Não"
            }

            this.grid.objetos.items[i].calcVenda = ((this.grid.objetos.items[i].detGanhoFrete - this.grid.objetos.items[i].detTaxaFrete) / this.grid.objetos.items[i].detValorFrete * 100).toFixed(2);
            this.grid.objetos.items[i].calcTabela = ((this.grid.objetos.items[i].custoFreteTabela - this.grid.objetos.items[i].custoFretePago)).toFixed(2);
            this.grid.objetos.items[i].calcEmbarque = Number(this.grid.objetos.items[i].detValorFrete - this.grid.objetos.items[i].embarqueCustoFrete + (this.grid.objetos.items[i].detGanhoFrete - this.grid.objetos.items[i].detTaxaFrete) / this.grid.objetos.items[i].embarqueCustoFrete * 100).toFixed(2);
            this.grid.objetos.items[i].calcAuditoria = ((this.grid.objetos.items[i].detValorFrete - this.grid.objetos.items[i].custoFretePago + (this.grid.objetos.items[i].detGanhoFrete - this.grid.objetos.items[i].detTaxaFrete)) / this.grid.objetos.items[i].custoFretePago * 100).toFixed(2);

             if (this.grid.objetos.items[i].calcAuditoria == "Infinity" || this.grid.objetos.items[i].calcAuditoria == "-Infinity" || this.grid.objetos.items[i].calcAuditoria == "NaN"){
              this.grid.objetos.items[i].calcAuditoria = ""
             }else{
              this.grid.objetos.items[i].calcAuditoria = this.grid.objetos.items[i].calcAuditoria
             }

            if (this.grid.objetos.items[i].calcVenda == "Infinity" || this.grid.objetos.items[i].calcVenda == "-Infinity" || this.grid.objetos.items[i].calcVenda == "NaN"){
              this.grid.objetos.items[i].calcVenda = ""
             }else{
              this.grid.objetos.items[i].calcVenda = this.grid.objetos.items[i].calcVenda
             }

            if (this.grid.objetos.items[i].calcTabela == "Infinity" || this.grid.objetos.items[i].calcTabela == "-Infinity" || this.grid.objetos.items[i].calcTabela == "NaN"){
              this.grid.objetos.items[i].calcTabela = ""
             }else{
              this.grid.objetos.items[i].calcTabela = this.grid.objetos.items[i].calcTabela
             }

            if (this.grid.objetos.items[i].calcEmbarque == "Infinity" || this.grid.objetos.items[i].calcEmbarque == "-Infinity" || this.grid.objetos.items[i].calcEmbarque == "NaN"){
              this.grid.objetos.items[i].calcEmbarque = ""
             }else{
              this.grid.objetos.items[i].calcEmbarque = this.grid.objetos.items[i].calcEmbarque
             }

          }
          this.grid.objetos.carregando = false;
          this.backupItems = JSON.parse(JSON.stringify(this.grid.objetos.items));
          this.dataInicio = this.filtros.data_inicio;
          this.dataFinal = this.filtros.data_final;
          this.modal.filtro = false;
      })
      .catch(error => {
        this.$swal({
          title: 'Alerta',
          text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
          icon: 'warning',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#4BB543',
          allowOutsideClick: true,
        });
      })
      .finally(() => {
        // this.tabItemsGrid(this.tabIndexGrid);
        this.pesquisando = false;
      });
    },
    async carregarTransportadoraBanco() {
      await axios.get(`${baseApiUrlPersys}/transportadora/empresa`, {
          headers: {
            'Token-API': this.$store.state.token_persys
          }
        }
      )
      .then(res => {
        this.dadosTransportadoraBanco = res.data;
        if(res.data.length > 0) {
          res.data.forEach(element => {
            if(!element.ignorarIntegracao) {
              if (
                !this.transportadoras.includes(
                  element.nome
                )
              ) {
                this.transportadoras.push(
                  element.nome
                );
                this.transportadorasCodigo.push(
                  Number(element.codigoSisfrete)
                );
              }
            }
          });
        }
      })
      .catch(() => {
        this.$swal({
          title: 'Alerta',
          text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
          icon: 'warning',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#4BB543',
          allowOutsideClick: true,
        });
      });
      await this.carregarTransportadorasPersys();
    },
    changePage(page) {
      this.grid.objetos.actualPage = page;
      if(this.lastTypeSearch == 0) {
        this.carregarObjetosEmpresaCinco(true);
      } else {
        this.buscarObjetos(false);
      }
      this.carregarItemsReverso(true);
    },
    changePageSearch(page) {
      this.grid.objetos.actualPage = page;
      this.buscarObjetos();
    },
    async carregarSubCanais() {
      await axios
        .get(`${baseApiUrlPersys}/token`,  {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          }
        )
        .then((res) => {
          if(res.data == ''){
            this.filtros.subCanais = [];
          }else{
            res.data.forEach(item => {
              if(item.tipoToken == "3") {
                this.filtros.subCanais.push(item);
              }
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        })
    },
    async reprocessAll() {
      this.loadingReprocessar = true;

        const response = await axios.get(`${baseApiUrlPersys}/order/objeto/embalamento/fail?fim=${this.dataFinal}&inicio=${this.dataInicio}`, {
          headers: {
            'Token-API': this.$store.state.token_persys
          }
        });

        for (const item of response.data) {

            await axios.get(`${baseApiUrlPersys}/order/calcular_frete/force/numero_pedido/${item.pedidoCanalVenda}`, {
              headers: {
                'Token-API': this.$store.state.token_persys
              }
            });
        }

        this.loadingReprocessar = false;
        this.grid.objetos.actualPage = 1;
        this.carregarObjetosEmpresaCinco();
        this.carregarItemsReverso();
    },
  },
  watch: {
    "grid.objetos.coluna": {
      handler: function(val, oldVal) {
        if (val != oldVal) {
          this.carregarObjetos();
        }
      },
    },
    "grid.objetos.direcao": {
      handler: function(val, oldVal) {
        this.carregarObjetos();
      },
    },
    "objeto.numero_rastreio": {
      handler: function(val, oldVal) {
        if (val == null || val == "") {
          this.objetoAtual = "";
          this.objeto.habilita = false;
          this.objeto.protocolo_reclamacao = "";
          this.objeto.numero_rastreio = "";
          this.objeto.rastreio = "";
          this.detalhes.enviado.data_postagem = "";
          this.detalhes.enviado.data_considerada = "";
          this.detalhes.enviado.cep = "";
          this.detalhes.enviado.cidade = "";
          this.detalhes.para.nome = "";
          this.detalhes.para.cep = "";
          this.detalhes.para.cidade = "";
          this.detalhes.para.bairro = "";
          this.detalhes.para.rua = "";
          this.detalhes.previsao_de_entrega.data = "";
          this.detalhes.previsao_de_entrega.em_domicilio = "";
          this.detalhes.entregue_em.data = "";
          this.detalhes.numero_rastreio = "";
          this.detalhes.order_id = "";
          this.detalhes.numero_danfe = "";
          this.grid.detalhes.items = [];
          this.reclamacao.codigo = "";
        }
      },
    },
    "filtros.allDates": {
      handler: function(val, oldVal) {
        if(val) {
          this.filtros.data_inicio = null;
          this.filtros.data_final = null;
        }
      }
    }
  },

  directives: {money: VMoney}
};
</script>

<style>
.v-badge__badge {
  min-width: 80px !important;
  border-radius: 6px !important;
  display: block !important;
}
.modalConferencia {
  padding-top: 20px;
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
}
.modalConferencia .inputModalConferencia {
  padding: 0 12px;
}
.modalConferencia .inputModalConferencia:first-child {
  padding: 12px 12px 0 12px;
}
.text-negative input {
  color: red !important;
}
.filtro_data {
  display: flex !important;
  align-items: center !important;
}
.alinhamento {
  padding-bottom: 0 !important;
  padding-top: 25px !important;
}

.alinhamentoHeader{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alinhamentoRow{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-decoration: none;
  cursor: pointer;
}

.alignmentDialog{
  display: flex;
  justify-content: flex-end;
}

/* .v-data-table-header tr th i {
  display: none;
} */

.itemGridGreenAndBold {
  color: green;
  font-weight: bold;
}

.btnSalvar {
  color: #fff !important;
}

.inputPrice input[type='number'] {
    -moz-appearance:textfield;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* A semi-transparent overlay */
  z-index: 9999; /* Place it above other elements */
}
</style>